import GtmController from "./controllers/gtm_controller.js"
import { post, get } from "./lib/request.js"
import { createSelectByValue, closeAllSelect } from "./controllers/select_newstyle_controller.js"

document.addEventListener("alpine:init", () => {
  // Formatting prices
  window.priceFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    useGrouping: true,
  })
  window.formatPriceWithSpace = (price) => {
    return window.priceFormatter.format(Math.ceil(price)).replace(/,/g, " ")
  }
  // END Formatting

  Alpine.store("stock", {
    stocks: [],
    stocksVisible: [],
    filteredStocks: [],
    filteredCategories: [],
    categories: [],
    product: null,
    keywords: [],
    filtredkeywords: [],
    filtredGranites: [],
    loaded: false,
    offset: 20,
    limit: 20,
    catalog: 1,
    tags: [],
    filters: { color: null, granite: null, category: null, keys: [], available: null, layout: null },
    error: false,
    isAccessory: false,
    layouts: [],
    colors: [],
    pictos: [], // keywords pictos
    async init() {
      const elementGranite = document.getElementById("stocks")
      if (elementGranite) {
        await this.getStocks() // Get colors
        initsearchInput([])
        this.getKeywords()
        this.loaded = true
      }
    },
    async getStocks() {
      this.loaded = false
      await getDataStocks().then(
        result => {
          if (result.stocks !== undefined) {
            this.stocks = JSON.parse(JSON.stringify(result.stocks))
            this.stocksVisible = JSON.parse(JSON.stringify(result.stocks))
            this.categories = JSON.parse(JSON.stringify(result.categories))
            this.filteredStocks = this.stocksVisible.slice(0, this.limit)
            this.keywords = JSON.parse(JSON.stringify(result.keywords))
            this.pictos = result.keywords
              .filter(keyword => keyword.picto && keyword.picto !== "")
            this.filtredkeywords = JSON.parse(JSON.stringify(result.keywords))
            this.layouts = result.layouts
            this.filteredCategories = this.categories.filter(category => category.type === "all" || this.stocksVisible.filter(stock => stock.keys.find(key => key.id === category.id) || stock.kind === category.id).length)
            createSelectByValue("select_stock_category", this.filteredCategories)
            this.loaded = true
            this.setupScrollListener()
          } else {
            this.error = true
          }
        }
      )
    },
    async getColors() {
      await getDataColors().then(
        result => {
          if (result.error !== undefined) {
            this.error = true
          } else {
            this.colors = result
          }
        }
      )
    },
    setupScrollListener() {
      document.querySelector(".new-catalog")?.addEventListener(
        "scroll",
        debounce(async (e) => {
          const scrollHeight = e.target.scrollHeight
          const scrollTop = e.target.scrollTop
          const clientHeight = e.target.clientHeight
          if (
            scrollTop + clientHeight >= scrollHeight / 3 &&
            this.limit < this.stocksVisible.length
          ) {
            this.limit += 20
            this.filteredStocks = this.stocksVisible.slice(0, this.limit)
          }
        }, 100)
      )
    },
    checkAvailable(stock, days) {
      if (parseInt(days) === 0 || stock.nextAvailabilityDate == null) {
        return stock.quantityAvailable > 0
      } else {
        const [year, month, day] = stock.nextAvailabilityDate.split("-")
        const nextAvailabilityDate = new Date(year, month - 1, day)
        const today = new Date()
        const searchedDate = new Date()

        searchedDate.setDate(today.getDate() + parseInt(days))
        today.setHours(0, 0, 0, 0)
        searchedDate.setHours(0, 0, 0, 0)
        nextAvailabilityDate.setHours(0, 0, 0, 0)

        return (nextAvailabilityDate >= today && nextAvailabilityDate <= searchedDate) || stock.quantityAvailable > 0
      }
    },
    onSearch(attr = false) {
      this.loaded = false
      if (attr) {
        if (attr.value === "" || attr.value === "null") {
          this.filters[attr.type] = null
        } else {
          if (attr.type === "keys") {
            this.filters[attr.type] = [...new Set(attr.value)]
          } else {
            this.filters[attr.type] = attr.value
          }
        }
        this.stocksVisible = this.stocks.filter(stock => {
          const { color, granite, category, keys, available, layout } = this.filters
          let matchCount = 0
          if (color !== null && stock.color === color) {
            matchCount++
          }
          if (keys.length > 0) {
            const checkKeys = keys.every(key =>
              stock.keys.some(obj =>
                this.keywords.some(keyObj => (keyObj.id === key || keyObj.name.toLowerCase().includes(key.toLowerCase())) && obj.id.includes(keyObj.id))
              )
            )
            if (checkKeys) {
              matchCount++
            }
          }
          if (category !== null && category !== "null") {
            if (stock.keys.find(key => key.id === category) || stock.kind === category) {
              matchCount++
            }
          }
          if (granite !== null && stock.granite === granite.split(" | ")[1]) {
            matchCount++
          }
          if (available !== null && this.checkAvailable(stock, available.split(" | ")[1])) {
            matchCount++
          }

          if (layout !== null && stock?.layout?.token === layout) {
            matchCount++
          }
          return matchCount === Object.values(this.filters).filter(val => val !== null && val !== "null" && !(Array.isArray(val) && val.length === 0)).length
        })
        this.limit = 20
        this.filteredStocks = this.stocksVisible.slice(0, this.limit)
        this.loaded = true
        let name = attr.value
        if (attr.type === "color") {
          name = attr.name.charAt(0).toUpperCase() + attr.name.slice(1)
          this.checkTags({ id: attr.value, name: name, type: attr.type })
        } else if (["available", "granite"].includes(attr.type) && attr.value !== null) {
          name = attr.value.split(" | ")[0]
          this.checkTags({ id: attr.value, name: name, type: attr.type })
        } else if (attr.type === "category" && attr.value !== null) {
          const selectedCategory = this.filteredCategories.find(category => category.id === attr.value)
          if (selectedCategory) {
            name = selectedCategory.name
          }
          this.checkTags({ id: attr.value === "null" ? "" : attr.value, name: name === "null" ? "" : name, type: attr.type })
        } else if (attr.type === "layout" && attr.value !== null) {
          this.checkTags({ id: attr.value === "null" ? "" : attr.value, name: attr.text === "null" ? "" : attr.text, type: attr.type })
        } else if (attr.type === "keys") {
          const keywordTags = this.keywords.filter(key => attr.value.includes(key.id)).map(searchedKey => ({ id: searchedKey.id, name: searchedKey.name, type: "keys" }))
          this.checkTags(keywordTags, true)
          this.checkTags(
            attr.value.filter(key => !keywordTags.some(tag => tag.id === key))
              .map(key => {
                return {
                  id: key,
                  name: key,
                  type: "keys"
                }
              }),
            false,
            true
          )
        }
      } else {
        this.stocksVisible = this.stocks.filter(stock => {
          const { color, granite, category, keys, available } = this.filters
          let matchCount = 0
          if (color !== null && stock.color === color) {
            matchCount++
          }
          if (keys.length > 0) {
            const checkKeys = keys.every(key =>
              stock.keys.some(obj =>
                this.keywords.some(keyObj => (keyObj.id === key || keyObj.name.toLowerCase().includes(key.toLowerCase())) && obj.id.includes(keyObj.id))
              )
            )
            if (checkKeys) {
              matchCount++
            }
          }
          if (category !== null && category !== "null") {
            if (stock.keys.find(key => key.id === category) || stock.kind === category) {
              matchCount++
            }
          }
          if (granite !== null && stock.granite === granite.split(" | ")[1]) {
            matchCount++
          }
          if (available !== null && this.checkAvailable(stock, available.split(" | ")[1])) {
            matchCount++
          }
          return matchCount === Object.values(this.filters).filter(val => val !== null && val !== "null" && !(Array.isArray(val) && val.length === 0)).length
        })
        // this.stocksVisible = this.stocks
        this.limit = 20
        this.filteredStocks = this.stocksVisible.slice(0, this.limit)
        this.loaded = true
      }
      let filter = []
      Object.entries(this.filters).forEach(filterAttr => {
        if (filterAttr[1] !== null) {
          filter.push({ name: filterAttr[0], value: filterAttr[1] })
        }
      })
      this.getKeywords()
      const { granite, category, available, layout } = this.filters
      if (granite == null) {
        cleanSelect("stock_granite")
      }
      if (category == null || category === "null") {
        cleanSelect("stock_category")
      }
      if (available == null) {
        cleanSelect("stock_availability")
      }
      if (layout == null) {
        cleanSelect("stock_layouts")
      }
    },
    onsearchbykey(keyword) {
      document.querySelector(".modal").classList.remove("is-open")
      document.querySelector(".modal").classList.remove("modal-center")
      let searchKeys = this.filters.keys
      if (!searchKeys.includes(keyword)) {
        searchKeys.push(keyword)
      }
      this.onSearch({ type: "keys", value: searchKeys })

      $("#search").val(this.filters.keys)
      document.body.modalController.close()
    },
    getKeywords() {
      let keys = this.stocksVisible.map(product => product.keys)
      let keywords = flattenarray(keys)
      const names = this.stocksVisible.map(product => product.is_acc ? product.name : product.reference)
      keywords = keywords.map(key => key.id).filter(onlyUnique).concat(names)
      this.filtredkeywords = [...this.keywords]
      this.filtredkeywords.forEach(keyword => {
        if (keywords.includes(keyword.id)) {
          keyword.hide = false
        } else {
          keyword.hide = true
        }
      })

      if (this.filters.category) {
        cleanSelect("stock_category", this.filters.category)
      }
    },
    checkTags(tagObject, iskeys = false, isText = false) {
      if (isText) {
        tagObject.forEach(tag => {
          if (!this.tags.some(t => t.id === tag.id)) {
            this.tags.push(tag)
          }
        })
        return
      }
      if (iskeys) {
        tagObject.forEach(tag => {
          // Check if the tag does not exist in this.tags
          if (!this.tags.some(existingTag => existingTag.id === tag.id)) {
            // If it doesn't exist, append it to this.tags
            this.tags.push(tag)
          }
        })
        return
      }
      const existingIndex = this.tags.findIndex(existingTag => existingTag.type === tagObject.type)
      if (existingIndex !== -1) {
        if (tagObject.name.length === 0) {
          this.tags.splice(existingIndex, 1)
        } else {
          this.tags[existingIndex] = tagObject
        }
      } else {
        if (tagObject.id !== "") {
          this.tags.push(tagObject)
        }
      }
    },
    checkImage(ref) {
      this.stocksVisible.forEach(stock => {
        if (stock.reference === ref) {
          stock.hide = true
        }
      })
    }
  })

  Alpine.store("granit", {
    async init() {
      const elementGranite = document.getElementById("catalogs-granites")
      if (elementGranite) {
        await this.getColors() // Get colors
        await this.getGranits()
        this.loaded = true
      }
    },
    loadedGranits: [],
    granits: [],
    searchGranit: [],
    loaded: false,
    limit: 20,
    catalog: 1,
    tags: [],
    filters: { color: null, origin: null, name: null },
    error: false,
    isAccessory: false,
    colors: [], // all colors
    async getGranits() {
      await getDataGra().then(
        result => {
          if (result.error !== undefined) {
            this.error = true
          } else {
            this.granits = result
            this.loadedGranits = result
            this.searchGranit = result
            this.loaded = true
            // GTM
            const isCatalogGranit = document.querySelector("[data-target='gtm.granitsPage']")
            if (isCatalogGranit) {
              GtmController.displayCatalogGranits(this.granits)
            }
            // end GTM
          }
        }
      )
    },
    async getColors() {
      await getDataColors().then(
        result => {
          if (result.error !== undefined) {
            this.error = true
          } else {
            this.colors = result
          }
        }
      )
    },
    onSearch(attr = false) {
      if (attr) {
        if (attr.value === "") {
          this.filters[attr.type] = null
        } else {
          this.filters[attr.type] = attr.value
        }
        this.granits = this.searchGranit.filter(granit => {
          const { color, name, origin } = this.filters
          let matchCount = 0
          if (color !== null && granit.color === color) {
            matchCount++
          }
          if (name !== null) {
            if (granit.reference.toLowerCase().indexOf(name.toLowerCase()) > -1 || granit.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
              matchCount++
            }
          }
          if (origin !== null && granit.origin === origin) {
            matchCount++
          }
          return matchCount === Object.values(this.filters).filter(val => val !== null).length
        })
        let name = attr.value
        if (attr.type === "color") {
          name = attr.name.charAt(0).toUpperCase() + attr.name.slice(1)
        }
        this.checkTags({ id: attr.value, name: name, type: attr.type })
      } else {
        this.granits = this.searchGranit.filter(granit => {
          const { color, name, origin } = this.filters
          let matchCount = 0
          if (color !== null && granit.color === color) {
            matchCount++
          }
          if (name !== null) {
            if (granit.reference.toLowerCase().indexOf(name.toLowerCase()) > -1 || granit.name.toLowerCase().indexOf(name.toLowerCase()) > -1) {
              matchCount++
            }
          }
          if (origin !== null && granit.origin === origin) {
            matchCount++
          }
          return matchCount === Object.values(this.filters).filter(val => val !== null).length
        })
      }

      // GTM
      let filter = []
      Object.entries(this.filters).forEach(filterAttr => {
        if (filterAttr[1] !== null) {
          filter.push({ name: filterAttr[0], value: filterAttr[1] })
        }
      })
      GtmController.displayGranitResultSearch(this.granits, filter)
      // end GTM

      const { origin } = this.filters
      if (origin == null) {
        cleanSelect("origin")
      }
    },
    onChangeInput(e) {
      this.onSearch({ type: "name", value: e.value })
      e.value = "" // clear input search
    },
    checkTags(tagObject) {
      if (tagObject.type === "name" && !tagObject.id) return // if value does not exist(empty or null) do not edit tags
      const existingIndex = this.tags.findIndex(existingTag => existingTag.type === tagObject.type)
      if (existingIndex !== -1) {
        if (tagObject.name.length === 0) {
          this.tags.splice(existingIndex, 1)
        } else {
          this.tags[existingIndex] = tagObject
        }
      } else {
        if (tagObject.id !== "") {
          this.tags.push(tagObject)
        }
      }
    }
  })

  Alpine.store("gpg", {
    product: null, // selected product
    preconfigSec: null, // selected secondary preconfig
    // variables used in modal
    productconfig: null,
    configImage: null,
    newconfig: false,
    hiddenTags: [],
    keys: [],
    // configure selected product
    initConfiguration(type, path) {
      let goTo = ""
      // Layout token
      let layout = Alpine.store("catalogs").layout || ""
      if (Alpine.store("catalogs").fromComparatorModal && Alpine.store("catalogs").comparatorLayout) {
        layout = Alpine.store("catalogs").comparatorLayout
      }
      const product = this.product
      const catalog = Alpine.store("catalogs").catalog
      let productNameByCatalog = product.is_cataName ? Buffer.from(product.footstoneName, "utf-8").toString("base64") : "" // eslint-disable-line no-undef
      switch (type) {
        case "ACC": // configure selected accessory
          goTo = `${path}/${product.reference}${product.gm}`
          break
        case "MNT": // configure selected monument
          goTo = `${path}/${product.reference}&${product.gm}&${product.preconfId}&${layout}&${product.gs}&${String(catalog)}&${productNameByCatalog}`
          if (this.preconfigSec) {
            // productNameByCatalog = this.preconfigSec.is_cataName ? Buffer.from(product.footstoneName, "utf-8").toString("base64") : ""
            goTo = `${path}/${product.reference}&${this.preconfigSec.granit || ""}&${this.preconfigSec.reference}&${layout}&${this.preconfigSec.gs || ""}&${String(catalog)}&${productNameByCatalog}`
          }
          break
        case "GRANITE":
          goTo = path
          break
      }
      return goTo
    },
    getLink(val) {
      Alpine.store("catalogs").getLink(val)
    },
    getNewconfig(ref, image = null, imageText = null, preconfig = null) {
      if (!this.product && preconfig) {
        this.product = Alpine.store("catalogs").productsVisible.find(prod => prod.reference === preconfig.monument_id)
      }
      if (ref === null) {
        this.newconfig = false
        this.productconfig = null
        this.configImage = this.product.image
        this.keys = Alpine.store("catalogs").allkeywords.filter(keyword => this.product.keys.includes(keyword.id))
      } else {
        if (imageText != null) { this.newconfig = imageText }
        this.configImage = image
      }
      this.preconfigSec = null
      if (preconfig) {
        this.newconfig = true
        this.preconfigSec = preconfig
        const preconfkeywords = this.product.keys.concat(preconfig.keys).filter(onlyUnique)
        this.keys = Alpine.store("catalogs").allkeywords.filter(keyword => preconfkeywords.includes(keyword.id))
      }
      this.productconfig = ref
    },
    onClickCategory(value, isAccessory = false) {
      if (isAccessory) {
        Alpine.store("catalogs").onClickCategoryAcc(value, true)
      } else {
        Alpine.store("catalogs").onClickCategory(value, true)
      }
    },
    async onClickColor(color, isAccessory = false, isGranit = false, isProductGranits = false, productfilter = false, isStock = false) {
      if (isProductGranits) {
        Alpine.store("productGranits").onFilterByColor(color, productfilter)
      } else if (isGranit) {
        Alpine.store("granit").onSearch({ type: "color", value: color, name: event.target.getAttribute("key") })
        this.color = color
      } else if (isStock) {
        Alpine.store("stock").onSearch({ type: "color", value: color, name: event.target.getAttribute("key") })
        this.color = color
      } else {
        // if we are if monument catalog => set layout of comparator to false => Get prices of comparator monuments without layout
        Alpine.store("catalogs").comparatorLayout = null
        await Alpine.store("catalogs").onClickColor(color, isAccessory)
        this.color = color
      }

      // GTM
      if (!isGranit && !isProductGranits) { // Accessory filter && Monuments filter
        GtmController.displayResultSearchByColor(Alpine.store("catalogs").filtredProducts, color, isAccessory)
      }
      // GTM end
    },
    reset(event, isAccessory = false, isGranit = false, isProductGranits = false, isStock = false) {
      // GTM - cta event
      GtmController.resetFilter(event.currentTarget, isAccessory, isGranit, isProductGranits)
      // END GTM
      if (isProductGranits) {
        Alpine.store("productGranits").reset()
      } else if (isGranit) {
        Alpine.store("granit").filters = { color: null, origin: null, name: null }
        Alpine.store("granit").granits = Alpine.store("granit").searchGranit
        Alpine.store("granit").tags = []
        if (document.getElementsByClassName("has-value").length > 0) {
          document.getElementsByClassName("has-value")[0].classList.remove("has-value")
        }
        cleanSelect("origin") // clear origin select
        // GTM - list res
        GtmController.displayGranitResultSearch(Alpine.store("granit").granits)
        // END GTM
      } else if (isStock) {
        Alpine.store("stock").filters = { color: null, granite: null, category: null, keys: [], available: null }
        Alpine.store("stock").tags = []
        Alpine.store("stock").stocksVisible = Alpine.store("stock").stocks
        Alpine.store("stock").limit = 20
        Alpine.store("stock").filteredStocks = Alpine.store("stock").stocksVisible.slice(0, Alpine.store("stock").limit)
        Alpine.store("stock").getKeywords()
        $("#search").val(Alpine.store("stock").filtredkeywords)
        cleanSelect("stock_granite")
        cleanSelect("stock_category")
        cleanSelect("stock_layouts")
        cleanSelect("stock_availability")
      } else if (isAccessory) {
        Alpine.store("catalogs").reset(isAccessory)
      } else {
        Alpine.store("catalogs").reset(false)
      }
    },
    isFiltred() {
      return Alpine.store("catalogs").category !== null || Alpine.store("catalogs").granit !== null || Alpine.store("catalogs").color !== null
    },
    async onClickGranit(granitName, isAccessory = false) {
      await Alpine.store("catalogs").onClickGranit(granitName, isAccessory, true)
    },
    async onSearchall(type, isAccessory = false) {
      if (Alpine.store("catalogs").granit !== null) {
        await Alpine.store("catalogs").onClickGranit(Alpine.store("catalogs").granit, isAccessory)
      } else if (Alpine.store("catalogs").color !== null) {
        await this.onClickColor(this.color, isAccessory)
      } else {
        this.loaded = true
      }
    },
    ondeselectTag(indexTag, isGranit = false, isStock = false) {
      if (isGranit) {
        const objectToDelete = Alpine.store("granit").tags.find((tag, index) => index === indexTag)
        Alpine.store("granit").filters[objectToDelete.type] = null
        Alpine.store("granit").tags = Alpine.store("granit").tags.filter((tag, index) => index !== indexTag)
        Alpine.store("granit").onSearch()
      } else if (isStock) {
        const objectToDelete = Alpine.store("stock").tags.find((tag, index) => index === indexTag)
        if (objectToDelete.type === "keys") {
          let index = Alpine.store("stock").filters.keys.indexOf(objectToDelete.id)
          if (index !== -1) {
            Alpine.store("stock").filters.keys.splice(index, 1)
          }
        } else {
          Alpine.store("stock").filters[objectToDelete.type] = null
        }
        const iskey = Alpine.store("stock").tags[indexTag]
        Alpine.store("stock").tags = Alpine.store("stock").tags.filter((tag, index) => index !== indexTag)
        Alpine.store("stock").onSearch()
        if (iskey.type === "keys") {
          const keywords = Alpine.store("stock").tags.filter((tag, index) => tag.type === "keys")
          $("#search").val(keywords.map(keyword => keyword.id))
        }
      } else {
        Alpine.store("catalogs").ondeselectTag(indexTag)
      }
    },
    async selectCatalog(catalog, load = false, isGranit = false) {
      if (isGranit) {
        Alpine.store("granit").granits = Alpine.store("granit").loadedGranits.filter(granit => {
          if (granit.catalogs === null) {
            return false
          }
          const catalogsArray = granit.catalogs.split(",")
          return catalogsArray.includes(catalog.toString())
        })
        Alpine.store("granit").searchGranit = Alpine.store("granit").granits
        Alpine.store("granit").onSearch()
        Alpine.store("granit").catalog = catalog
      } else {
        Alpine.store("catalogs").selectCatalog(catalog, load)
      }

      callGetPrices(Alpine.store("catalogs").productsVisible)
    },
  })

  // Stocks Storage
  Alpine.store("catalogs", {
    // monuments, accessories
    products: [],
    productsVisible: [],
    oldproductsVisible: [],
    filtredProducts: [],
    filtredPricesProducts: [],
    monument: null, // selected monument
    accessory: null, // selected accessory
    priceCache: {}, // Cache for storing product prices
    offset: 20,
    limit: 20,
    // keywords list
    allkeywords: [],
    keywords: [],
    filtredkeywords: [],
    matching_colors: [],

    categories: [], // categories list
    filteredCategories: [], // categories list
    layouts: [], // layouts list
    hiddenTags: [], // hidden tags

    loaded: false,
    priceLoading: false,
    catalogLoadPrice: true,
    category: null, // selected category
    layout: null, // selected layout
    layoutSizes: null,
    granit: null, // selected granite
    color: null, // selected color
    searchkeys: [], // selected keywords
    pictos: [], // keywords pictos

    monuments_granits: [],

    granites: [],
    filtredGranites: [],

    tags: [], // tags

    catalog: 0, // selected catalog
    catalog_type: "",

    categorysup: null,
    organization: null,

    onsearch: false,

    lastConfiguration: null,
    fromProductGranits: false,
    // categorysup: null,
    // comparator variables
    monumentsToCompare: [],
    comparatorPopup: false,
    comparatorPopupHead: false,
    comparatorColor: null,
    comparatorLayout: null,
    comparatorLayoutDisplay: false,
    comparatorLoadPrice: false,
    fromComparator: false,
    fromComparatorModal: false,
    // END comparator variables
    error: false,
    initialized: false,
    async init() {
      const elementACC = document.getElementById("accessories-catalog")
      const elementCata = document.getElementById("catalogs")
      this.filtredProducts = []
      this.oldproductsVisible = []
      this.productsVisible = []
      this.loaded = false
      this.initialized = false
      if (elementACC) {
        await Alpine.store("granit").getColors() // Get colors
        await this.getAccessories()
        GtmController.displayCatalogProducts(this.filtredProducts, "ACC", this.limit)
        this.loaded = true
        this.setupScrollListener("ACC")
        this.initialized = true
      }
      if (elementCata) {
        await Alpine.store("granit").getColors() // Get colors
        await this.getMonuments()
        GtmController.displayCatalogProducts(this.filtredProducts, "MNT", this.limit)
        this.loaded = true
        this.setupScrollListener("MNT")
        this.initialized = true
      }
      $(".select2-search__field").addClass("text-dark-placeholder font-raleway")
    },
    sortedProducts(products = false, hidden = true, isRecursiveCall = false) {
      if (products) {
        this.productsVisible = products.sort((p1, p2) => p1.reference - p2.reference)
        this.productsVisible = products.sort((p1, p2) => parseInt(p1.order) - parseInt(p2.order))
        if (hidden) {
          this.productsVisible = products.sort((a, b) => a.hide - b.hide)
        }
      } else {
        this.productsVisible = this.productsVisible.sort((p1, p2) => p1.reference - p2.reference)
        this.productsVisible = this.productsVisible.sort((p1, p2) => parseInt(p1.order) - parseInt(p2.order))
        if (hidden) {
          this.productsVisible = this.productsVisible.sort((a, b) => a.hide - b.hide)
        }
      }
      if (isRecursiveCall) {
        let showedProducts = this.productsVisible.slice(this.limit - this.offset, this.limit)
        let productsWithNullPrices = showedProducts.filter(product => product.prices === null)
        if (productsWithNullPrices.length > 0) {
          // callGetPrices(productsWithNullPrices, false, true, false, true)
          this.sortedProducts(false, hidden)
        }
      }
    },
    setupScrollListener(type) {
      this.sortedProducts()
      document.querySelector(".new-catalog")?.addEventListener(
        "scroll",
        debounce(async (e) => {
          const scrollHeight = e.target.scrollHeight
          const scrollTop = e.target.scrollTop
          const clientHeight = e.target.clientHeight
          if (
            scrollTop + clientHeight >= scrollHeight / 3 &&
            this.limit < this.productsVisible.length
          ) {
            this.limit += this.offset
            // Adjusted the indentation here
            this.productsVisible.forEach(product => {
              const ref = product.reference
              const graniteRef = product.gm || ""
              const graniteSecondaryRef = product.gs || ""
              const cachedPrice = Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${graniteSecondaryRef}`]
              if (cachedPrice) {
                product.prices = cachedPrice.prices
              }
            })
            const nextProducts = this.productsVisible.slice(this.limit - this.offset, this.limit)
            let isAccessory = false
            if (type === "ACC") { isAccessory = true }
            // Get prices
            callGetPrices(nextProducts, isAccessory).then(() => {
              // After getting prices and resort the products
              // Get images of next products
              this.renderProducts(false, type)
            })
          }
        }, 100)
      )
    },
    renderProducts(load, type) {
      // this.sortedProducts()
      let products = this.productsVisible.slice(0, this.limit)
      let index = 0
      // load Images
      let laodImgsLoop = new Promise((resolve, reject) => {
        products.slice(0 - this.offset).forEach(async product => {
          const img = await getImage(product.gm, product, "large", type === "ACC")
          product.image = img.image
          index++
          if (products.slice(0 - this.offset).length === index) { resolve() }
        })
        // LOOP FINISHED
      })
      laodImgsLoop.then(() => {
        // Set results
        this.filtredProducts = products
        // GTM
        GtmController.displayCatalogProducts(this.filtredProducts, type, this.offset)
        // end GTM
      })
    },
    async getAccessories() {
      this.loaded = false
      await getDataAcc()
        .then(result => {
          if (result.accessories !== undefined) {
            this.loaded = false
            this.organization = result.current_organization
            this.products = result.accessories
            this.productsVisible = JSON.parse(JSON.stringify(result.accessories))
            this.oldproductsVisible = JSON.parse(JSON.stringify(result.accessories))
            this.sortedProducts()
            this.filtredProducts = this.productsVisible.slice(0, this.limit)
            this.allkeywords = result.keywords
            this.keywords = JSON.parse(JSON.stringify(result.keywords))
            this.granites = result.granites
            this.isAccessory = true
            initsearchInput([])
            this.getKeywords(true)
            // this.keywords.unshift({ id: "", name: "Voir toute la liste", catalog: "0" })
          } else {
            this.error = true
          }
          // this.loaded = true
        })
      // GET PRICES
      const firstScroll = this.productsVisible.slice(0, this.limit)
      callGetPrices(firstScroll, true, false)
    },
    async getMonuments() {
      await getData().then(
        async result => {
          if (result.monuments !== undefined) {
            this.organization = result.current_organization
            this.catalog_type = result.catalog_type
            this.products = result.monuments
            this.monuments_granits = result.granit_list
            this.isBicolorProducts = this.products.filter(product => product.isBicolore && product.isLinked)
            this.oldproductsVisible = JSON.parse(JSON.stringify(result.monuments))
            this.productsVisible = JSON.parse(JSON.stringify(result.monuments))
            this.sortedProducts()
            this.products = this.products.filter(product => !product.isLinked)
            this.oldproductsVisible = this.oldproductsVisible.filter(product => !product.isLinked)
            this.productsVisible = this.productsVisible.filter(product => !product.isLinked)
            this.filtredProducts = this.productsVisible.slice(0, this.limit)
            this.categories = result.categories
            this.layouts = result.layouts
            this.hiddenTags = result.hiddenTags
            this.matching_colors = result.match_color
            this.matching_color_list = result.match_color
            this.granites = result.granites
            this.allkeywords = result.keywords
            this.pictos = result.keywords
              .filter(keyword => keyword.picto && keyword.picto !== "")
            this.keywords = JSON.parse(JSON.stringify(result.keywords))
            this.filtredGranites = [...result.granites].map(g => g.color === 0 ? ({ id: null, name: g.name }) : ({ id: `${g.reference}-${g.name}`, name: g.name }))
            // this.keywords.unshift({ id: "", name: "Voir toute la liste", catalog: "0" })
            // Init list of monuments to compare
            if (localStorage.getItem("comparatorRefs")) {
              const monumentsRefToCompare = JSON.parse(localStorage.getItem("comparatorRefs"))
              const monumentsRef = monumentsRefToCompare.find(obj => obj.organization === this.organization.clientId)
              if (monumentsRef && monumentsRef.monuments.length > 0) {
                monumentsRef.monuments.forEach(monumentRef => {
                  const monument = this.products.find(m => m.reference === monumentRef)
                  if (monument) {
                    this.monumentsToCompare.push(monument)
                  }
                })
                if (this.monumentsToCompare.length > 0) {
                  this.comparatorPopup = true
                }
              }
            }
            await this.selectCatalog(result.catalog, true)
            initsearchInput(this.searchkeys)
            // end Init
          } else {
            this.error = true
          }
        }
      )

      // const firstScroll = this.productsVisible.slice(0, this.limit)
      // GET PRICES
      // await callGetPrices(firstScroll, false, false)
    },
    getLink(val) {
      let params = new URLSearchParams(window.location.search)
      let keys = this.tags.map(tag => tag.id) // get all keys

      keys.push(val) // add val to keys
      keys = keys.filter(onlyUnique).join(",")
      // convert the object to a query string
      params.set("keywords", keys.toString())
      // and overwrite the existing query string
      const url = window.location.origin + window.location.pathname + "?" + params.toString()
      window.location.href = url
    },
    getKeywords(isAccessory = false) {
      if (isAccessory) {
        this.filtredkeywords = [...this.keywords]
        this.filtredkeywords.forEach(keyword => keyword.isAcc = true)
        return
      }
      let keys = this.productsVisible.map(product => product.keys)
      let keywords = flattenarray(keys)
      const names = this.productsVisible.map(product => product.reference)
      keywords = keywords.filter(onlyUnique).concat(names)
      this.filtredkeywords = [...this.keywords]
      this.filtredkeywords.forEach(keyword => {
        if (keywords.includes(keyword.id)) {
          if (!keyword.iskeyword) {
            const monument = this.productsVisible.find(product => product.reference === keyword.id)
            if (monument) {
              keyword.name = monument.footstoneName + (monument.headstone && monument.headstone !== "" ? ` stèle ${monument.headstone}` : "")
            }
          }
          keyword.hide = false
        } else {
          keyword.hide = true
        }
      })
      cleanSelect("category", this.category)
    },
    async reset(isAccessory) {
      this.color = null
      this.granit = null
      this.category = null
      this.layout = null
      const keywords = this.tags.filter(tag => tag.type === "keyword").map(tag => tag.id)
      if (isAccessory) {
        this.oldproductsVisible = JSON.parse(JSON.stringify(this.products))
        this.productsVisible = [...this.oldproductsVisible]
        await this.onClickGranit(null, true)
      } else {
        await this.onClickGranit(null)
        await this.onSearch(keywords, false, true)
        cleanSelect("category")
        cleanSelect("layout")
      }
      // GTM
      GtmController.displayResultSearch(this.productsVisible.slice(0, this.limit), [], isAccessory, true)
      // end GTM
    },
    isFiltred() {
      return this.category !== null || this.granit !== null || this.color !== null
    },
    async onSearchall(type, isAccessory = false) {
      if (this.granit !== null) {
        await this.onClickGranit(this.granit, isAccessory)
      } else if (this.color !== null) {
        await this.onClickColor(this.color, isAccessory)
      }
    },
    async selectCatalog(catalog, load = false) { // select new catalog
      this.loaded = false
      this.limit = this.offset
      this.productsVisible = []
      this.oldproductsVisible = []
      this.filtredProducts = []
      this.keywords = []
      this.filtredLayouts = []

      await loadingImages(this.filtredProducts, ".comparator-popup")

      let keys = JSON.parse(JSON.stringify(this.allkeywords))
      let layouts = JSON.parse(JSON.stringify(this.layouts))
      this.keywords = keys
      // this.keywords.unshift({ id: "", name: "Voir toute la liste", catalog: "0" })
      this.filtredLayouts = layouts.filter(layout => layout.catalog === "0" || Object.values(layout.catalog).includes(catalog.toString()))
      createSelectByValue("select_layout", this.filtredLayouts)
      // Create granit select
      createSelectByValue("select_granite", this.filtredGranites)
      // end init granit
      let monuments = JSON.parse(JSON.stringify(this.products))
      if (catalog !== null) {
        this.catalog = parseInt(catalog)
        monuments = monuments.filter(item => item.catalogs.map(catalogitem => String(catalogitem.id)).includes(String(catalog)))
      } else {
        monuments = JSON.parse(JSON.stringify(this.products))
      }
      this.filtredProducts = []
      if (this.catalog) {
        for (let i = 0; i < monuments.length; i++) {
          const catalogRef = monuments[i].catalogs.find(catalogItem => parseInt(catalogItem.id) === this.catalog)
          if (catalogRef && catalogRef.name !== "") {
            monuments[i].footstoneName = catalogRef.name
            monuments[i].is_cataName = true
          } else {
            monuments[i].footstoneName = monuments[i].defaultName
            monuments[i].is_cataName = false
          }
        }
        this.monumentsToCompare.forEach(monumentRef => {
          const cataRef = monumentRef.catalogs.find(catalogItem => parseInt(catalogItem.id) === this.catalog)
          if (cataRef && cataRef.name !== "") {
            monumentRef.footstoneName = cataRef.name
            monumentRef.is_cataName = true
          } else {
            monumentRef.footstoneName = monumentRef.defaultName
            monumentRef.is_cataName = false
          }
        })
      }
      this.productsVisible = [...monuments]
      this.oldproductsVisible = [...monuments]
      this.getKeywords()
      initsearchInput(this.searchkeys)
      let loadprices = false
      if (this.tags.length !== 0) {
        await this.onSearch(this.tags.map(tag => tag.id), false, false, true, true)
        loadprices = true
      } else if (this.granit !== null) {
        await this.onClickGranit(this.granit)
        loadprices = true
      } else if (this.color !== null) {
        await this.onClickColor(this.color)
        loadprices = true
      }
      let keylist = flattenarray(this.productsVisible.map(product => product.keys))
      this.filteredCategories = this.categories.filter(category => (category.id === null || keylist.includes(category.id.toString())))

      if (load) {
        await this.setvalues()
      }
      this.sortedProducts()

      this.filtredProducts = await this.productsVisible.slice(0, this.limit)
      if (!loadprices) {
        await callGetPrices(this.filtredProducts.filter(prod => !prod.prices))
      }

      cleanSelect("category")
      createSelectByValue("select_category", this.filteredCategories)
      cleanSelect("category", this.category)
      this.loaded = true
    },
    async setvalues() {
      const query = window.location.search
      const urlParams = new URLSearchParams(query)
      if (urlParams.has("keywords")) {
        let keywords = urlParams.get("keywords")
        const keywordlist = keywords === "" ? [] : keywords.split(",")
        await this.onSearch(keywordlist)
      }
      if (urlParams.has("granite")) {
        await loadingImages(this.filtredProducts, ".comparator-popup")
        let granite = urlParams.get("granite")
        await this.onClickGranit(granite)
        cleanSelect("granite", granite) // active selected value in granite select
      }
    },
    ondeselectTag(indexTag) {
      this.loaded = false
      const objectToDelete = this.tags.find((tag, index) => index === indexTag)
      // if(objectToDelete.type === "category" && objectToDelete.selected) {
      //   cleanSelect("category")
      // }
      if (objectToDelete.type === "layout") {
        this.layout = null
        cleanSelect("layout")
      }
      if (objectToDelete.type === "keyword" || objectToDelete.type === "category" || objectToDelete.type === "layout") {
        const keys = this.tags.filter(key => key.id !== objectToDelete.id).map(tag => tag.id)
        this.onSearch(keys, false, true, true, true)
          .then(() => {
            // GTM
            GtmController.displayResultSearch(this.productsVisible.slice(0, this.limit), [], false, true)
            // end GTM
          })
        $("#search").val(this.searchkeys)
      }
      cleanSelect("category", this.category)

      this.loaded = true
    },
    onsearchbykey(keyword) {
      document.querySelector(".modal").classList.remove("is-open")
      document.querySelector(".modal").classList.remove("modal-center")
      const keys = this.tags.map(tag => tag.id)
      keys.push(keyword)

      if (this.isAccessory) {
        this.onSearchACC(keys)
      } else {
        this.onSearch(keys)
      }

      $("#search").val(this.searchkeys)
      document.body.modalController.close()
    },
    async onSearch(value, isAccessory = false, deselect = false, event, notFirstSearch = false) {
      this.limit = this.offset
      this.loaded = false

      // Search by keywords
      if (value.length === 0) {
        this.searchkeys = value
        this.category = null
        this.productsVisible = [...this.oldproductsVisible]
        this.tags = value
        if (deselect) this.loaded = true
        this.getKeywords(isAccessory)
      } else {
        this.searchkeys = []
        let newkeys = []
        if (deselect) {
          // this.onsearch = true
          this.productsVisible = [...this.oldproductsVisible]
          this.productsVisible.forEach(product => {
            const pdt = this.products.find(pdt => pdt.reference === product.reference && pdt.preconfId === product.preconfId)
            product.image = pdt.image
            product.gm = pdt.gm
            product.gs = pdt.gs
            product.hide = pdt.hide
          })
        }
        value.forEach(keyword => {
          let selected = false
          const keywordslist = this.keywords
          const keywordsearched = keywordslist.filter(keyword2 => keyword2.name.toLowerCase().includes(keyword.toLowerCase()))
          const key = keywordslist.find(keyword2 => keyword2.id.toString() === keyword || (keyword2.name.toLowerCase().includes(keyword.toLowerCase()) && keywordsearched.length === 1))
          const category = this.categories.find(category => (category.id === keyword.toString() || category.id === key?.id) && keyword !== null)
          const layout = this.layouts.find(layout => layout.id === keyword)
          if (layout) {
            this.layout = keyword
            const inTags = this.tags.find(tag => tag.id === keyword.toString())
            if (!inTags) newkeys.push({ id: layout.id.toString(), name: layout.name, type: "layout" })
            this.searchkeys.push(keyword)
          } else if (category) {
            this.searchkeys.push(category.id)
            if (this.category === null || (key && this.category === key.id)) {
              if (key) {
                this.category = key.id
              } else {
                this.category = category.id
              }
              cleanSelect("category", this.category)
              selected = true
            }
            const inTags = this.tags.find(tag => tag.id === keyword.toString() || tag.id === key?.id)
            if (!inTags) {
              if (key) {
                newkeys.push({ id: category.id, name: key.name, type: "category", selected: selected })
              }
            } else {
              if (!inTags.selected) inTags.selected = selected
            }
          } else {
            const inTags = this.tags.find(tag => tag.id === keyword.toString() || tag.id === key?.id)
            if (key) {
              if (!inTags) {
                newkeys.push({ id: key.id.toString(), name: key.name, type: "keyword" })
              } else {
                inTags.name = key.name
              }
              this.searchkeys.push(key.id)
            } else {
              if (!inTags) newkeys.push({ id: keyword.toString(), name: keyword.toString(), type: "keyword" })
              this.searchkeys.push(keyword)
            }
          }
        })
        if (deselect) {
          this.tags = this.tags.filter(tag => this.searchkeys.includes(tag.id))
          if (!this.tags.find(tag => tag.id === this.category)) {
            this.category = null
          }
        } else {
          this.tags = this.tags.concat(newkeys)
        }

        const monuments = this.productsVisible.filter(item => {
          const fullname = item.footstoneName + (item.headstone && item.headstone !== "" ? ` stèle ${item.headstone}` : "")
          const result = this.searchkeys.filter(key => item.keys.includes(key.toString()) ||
          key === item.reference || fullname.toLowerCase().includes(key.toString().trim().toLowerCase()) || this.keywords.find(keyword => keyword.id === key && keyword.name.toLowerCase() === fullname.toLowerCase()))
          return result.length === value.length
        })
        this.productsVisible = monuments
        this.getKeywords(isAccessory)
      }
      this.filtredProducts = []
      // onSearchall => Search by color or granite
      this.onSearchall().then(async () => {
        this.sortedProducts()
        this.filtredProducts = await this.productsVisible.slice(0, this.limit)
        this.loaded = true
        cleanSelect("layout", this.layout)
        if (notFirstSearch) {
          // GET PRICES
          await callGetPrices(this.filtredProducts, isAccessory)
        }
        // GTM
        if (event?.currentTarget?.name === "search[]" && value.length !== 0) {
          const addedVal = value[value.length - 1]
          GtmController.displayResultSearchWithInput(this.filtredProducts, addedVal, false)
        }
        // END GTM
      })
    },
    ondeselectACCTag(indexTag) {
      this.loaded = false
      const objectToDelete = this.tags.find((tag, index) => index === indexTag)
      if (objectToDelete.type === "category") {
        this.onClickCategoryAcc(null)
      } else if (objectToDelete.type === "keyword") {
        const keys = this.tags.filter(key => key.id !== objectToDelete.id).map(tag => tag.id)
        this.onSearchACC(keys)
        $("#search").val(this.searchkeys)
        // GTM
        if (this.granit === null && this.color === null && this.category === null && this.searchkeys.length === 0) {
          GtmController.displayResultSearch(this.filtredProducts, [], true, true)
        }
        // END GTM
      }
      this.loaded = true
    },
    async onSearchACC(value, event) {
      this.limit = this.offset
      this.tags = this.tags.filter(tag => tag.type !== "keyword")
      if (value.length === 0) {
        this.searchkeys = value
        if (!this.onsearch) {
          this.onsearch = true
          if (this.category === null) {
            this.productsVisible = [...this.oldproductsVisible]
            this.loaded = true
          } else {
            this.onClickCategoryAcc(this.category)
          }
        }
      } else {
        this.searchkeys = value
        let newkeys = []
        if (!this.onsearch) {
          this.onsearch = true
          if (this.category === null) {
            this.productsVisible = [...this.oldproductsVisible]
          } else {
            this.onClickCategoryAcc(this.category)
          }
        }
        value.forEach(keyword => {
          const keywordslist = this.keywords
          const key = keywordslist.find(keyword2 => keyword2.id === keyword)
          const inTags = this.tags.find(tag => tag.type === "keyword" && tag.id === keyword.toString())
          if (key) {
            if (!inTags) newkeys.push({ id: keyword, name: key.name, type: "keyword" })
          } else if (!inTags) newkeys.push({ id: keyword.toString(), name: keyword.toString(), type: "keyword" })
        })
        this.tags = this.tags.concat(newkeys)
        this.productsVisible = this.productsVisible.filter(item => {
          const result = this.searchkeys.filter(key => key === item.reference || item.description.toLowerCase().includes(key.toString().trim().toLowerCase()))
          return result.length === this.searchkeys.length
        })
      }
      if (this.granit != null) {
        this.onClickGranit(this.granit, true)
      } else if (this.color != null) {
        this.onClickColor(this.color, true)
      }
      this.sortedProducts()
      this.filtredProducts = this.productsVisible.slice(0, this.limit)
      this.onsearch = false

      // GET PRICES
      await callGetPrices(this.filtredProducts, true)

      // GTM
      if (event?.currentTarget?.name === "search[]" && value.length !== 0) { // search with input seach
        GtmController.displayResultSearchWithInput(this.filtredProducts, false, true)
      }
      // END GTM
    },
    monumentgranits(link, catalogType = false) {
      const monument = this.monument
      let cachekey = monument.cachekey
      if (catalogType) {
        return link + "?monument=" + monument.reference + "&preconfig=" + monument.preconfId + "&cache_key=" + cachekey + "&catalog_type=" + catalogType
      }
      return link + "?monument=" + monument.reference + "&preconfig=" + monument.preconfId + "&cache_key=" + cachekey
    },
    accessorygranits(link, catalogType = false) {
      if (catalogType) {
        return link + "&catalog_type=" + catalogType
      }
      return link
    },
    async getAccessory(ref, granitName = "false") {
      let accessory
      if (this.fromProductGranits === true) {
        accessory = Alpine.store("productGranits").product
        this.fromProductGranits = false
      } else {
        accessory = this.productsVisible.find(item => item.reference === ref)
      }
      this.accessory = JSON.parse(JSON.stringify(accessory))
      Alpine.store("gpg").product = this.accessory
      this.configImage = this.accessory.image
      Alpine.store("gpg").configImage = ""
      if (granitName !== "false" && granitName !== "") {
        const granitInfo = granitName.split("-")
        let img = await getImage(granitInfo[0], accessory, "large", true)
        Alpine.store("gpg").configImage = img.image
        this.accessory.image = img.image
        this.accessory.gm = granitInfo[0]
      } else {
        let img = await getImage(this.accessory.gm, accessory, "large", true)
        this.accessory.image = img.image
        Alpine.store("gpg").configImage = this.accessory.image
      }
    },
    async getMonument(monument, granitName = "false", granitSecondaryName = "") {
      Alpine.store("gpg").hiddenTags = this.hiddenTags
      this.monument = JSON.parse(JSON.stringify(monument))
      Alpine.store("gpg").product = this.monument

      if (Alpine.store("gpg").preconfigSec) {
        const preconfKeys = Alpine.store("gpg").preconfigSec.keys
        const combinedKeys = [...new Set([...Alpine.store("gpg").product.keys, ...preconfKeys])]
        Alpine.store("gpg").keys = combinedKeys
      } else {
        Alpine.store("gpg").keys = Alpine.store("gpg").product.keys
      }
      Alpine.store("gpg").keys = Alpine.store("gpg").keys.map(key => {
        return this.allkeywords.find(keyword => key && keyword && keyword.id === key)

      }).filter(Boolean)
      // check if preconf has same reference monument as the current monument
      if (Alpine.store("gpg").preconfigSec && (this.monument.reference !== Alpine.store("gpg").preconfigSec.monument_id)) {
        Alpine.store("gpg").preconfigSec = null
      }

      Alpine.store("gpg").configImage = ""
      if (granitName !== "false" && granitName !== "" && Alpine.store("gpg").preconfigSec === null) {
        let img = await getImage(granitName.split("-")[0], monument, "large", false, false, false, granitSecondaryName.split("-")[0])

        this.monument.image = img.image
        Alpine.store("gpg").configImage = img.image
        this.monument.gm = granitName.split("-")[0]
        this.monument.gs = granitSecondaryName.split("-")[0]
      } else {
        let img = await getImage(this.monument.gm, monument, "large", false, false, false, this.monument.gs)
        Alpine.store("gpg").configImage = img.image
      }
      // Get preconfigs images
      const loadImgs = new Promise((resolve, reject) => {
        this.monument.preconfigs.forEach(async (preconfig, index) => {
          const preconfData = Alpine.store("gpg").preconfigSec && (preconfig.reference === Alpine.store("gpg").preconfigSec.reference)
            ? { gm: Alpine.store("gpg").preconfigSec.gm, gs: Alpine.store("gpg").preconfigSec.gs, prices: Alpine.store("gpg").preconfigSec.prices }
            : null
          const img = await getImage(this.monument.gm, this.monument, "large", false, preconfData === null ? null : preconfData.gm, preconfig, preconfData === null ? "" : preconfData.gs)
          preconfig.image = img.image
          if (preconfData) {
            preconfig.granit = preconfData.gm
            preconfig.gm = preconfData.gm
            preconfig.gs = preconfData.gs
            preconfig.prices = preconfData.prices
            Alpine.store("gpg").configImage = img.image
          }

          // LOPP FINISHED
          if (this.monument.preconfigs.length === index + 1) { resolve() }
        })
      })
      // Get prices
      await getPrices([Alpine.store("gpg").product], false).then(
        result => {
          if (result.prices !== undefined) {
            result.prices.forEach(priceItem => {
              if (priceItem.prices.buying === 0) {
                Alpine.store("gpg").product.noPrice = true
              }
              Alpine.store("gpg").product.prices = priceItem.prices
            })
          } else {
            Alpine.store("gpg").error = true
          }
        }
      )
      loadImgs.then(() => {
        const elementCatalog = document.getElementById("result")
        const catalogTypeValue = elementCatalog.getAttribute("data-catalog-type")
        if (catalogTypeValue !== "pro") return
        getPreconfigsPrice(this.monument.preconfigs).then(
          result => {
            if (result.prices !== undefined) {
              result.prices.forEach(priceItem => {
                let productIndex = this.monument.preconfigs.findIndex(prod => prod.reference === priceItem.reference)
                if (productIndex !== -1) {
                  this.monument.preconfigs[productIndex].noPrice = false
                  if (priceItem.prices.buying === 0) {
                    this.monument.preconfigs[productIndex].noPrice = true
                  }
                  this.monument.preconfigs[productIndex].prices = priceItem.prices
                }
              })
            } else {
              Alpine.store("catalogs").error = true
            }
          }
        ).then(() => {
          if (Alpine.store("gpg").preconfigSec) {
            let preconfSelected = this.monument.preconfigs.find(preconf => preconf.reference === Alpine.store("gpg").preconfigSec.reference)
            Alpine.store("gpg").preconfigSec.prices = preconfSelected.prices
            if (preconfSelected.prices.buying === 0) {
              Alpine.store("gpg").preconfigSec.noPrice = true
            }
          }
        })
      })
      // END get preconfigs images
    },
    async onClickLayout(layout, layoutText) { // filter products with selected layout
      this.priceLoading = true
      this.limit = 20
      this.productsVisible = [...this.oldproductsVisible]
      this.tags = this.tags.filter(tag => tag.type !== "layout")
      let layoutSelected = false
      if (layout === "null" || layout === null || layout === "") {
        this.layout = null
        cleanSelect("layout", this.layout)
        // this.productsVisible = monuments
      } else {
        let newkeys = []
        this.layout = layout
        layoutSelected = this.filtredLayouts.find(layoutObject => layoutObject.id === layout)
        newkeys.push({ id: layoutSelected.id, name: layoutSelected.name, type: "layout" })
        this.tags = this.tags.concat(newkeys)
        // Get the layout size
        this.layoutSizes = layoutText.match(/ - (.+)/)[1]
      }
      this.onSearch(this.tags.map(tag => tag.id))
      this.productsVisible.forEach(product => {
        product.noPrice = false
      })
      this.sortedProducts()
      await callGetPrices(this.productsVisible.slice(0, this.limit), false, true)
      // GTM
      GtmController.displayResultSearchBylayout(this.productsVisible.slice(0, this.limit), layoutSelected)
      // end GTM
    },
    async onClickCategory(type, callGtmEvent = false) {
      this.limit = this.offset
      let monuments = [...this.oldproductsVisible]
      // Reset le texte si la recherche est faite par catégorie, pour bien
      // montrer que le texte est ignoré.
      let monumentComponents = getMonumentCategory()
      if ((type in monumentComponents) && type !== "ACC") {
        console.log("type")
      } else {
        if (type === null || type === "") {
          this.tags = this.tags.filter(tag => !tag.selected)
          this.category = null
          cleanSelect("category", this.category)
          this.productsVisible = monuments
        } else {
          let newkeys = []
          this.productsVisible = monuments
          const category = this.categories.find(category => category.id === type && type !== null)
          const inTags = this.tags.find(tag => tag.type === "category" && tag.id === type)
          this.tags = this.tags.filter(tag => !tag.selected)

          this.category = type
          if (category && !inTags) {
            const key = this.keywords.find(keyword => keyword.id === category.id)
            const categoryName = key ? key.name : category.name
            newkeys.push({ id: category.id, name: categoryName, type: "category", selected: true })
          }
          this.tags = this.tags.concat(newkeys)
        }
        this.onSearch(this.tags.map(tag => tag.id))
        let isAccessory = false
        if (type === "ACC") {
          isAccessory = true
        }
        // GET PRICES
        await callGetPrices(this.productsVisible, isAccessory)
      }
      this.sortedProducts()

      // GTM
      if (callGtmEvent) {
        GtmController.displayResultSearchByCategory(this.productsVisible.slice(0, this.limit), this.tags, type === "ACC")
      }
      // GTM end
    },
    async onClickGranit(granitName, isAccessory = false, callGtmEvent = false) {
      let granit = granitName === null || granitName === 0 ? null : granitName.split("-")[0]
      let products = []
      this.limit = this.offset
      await loadingImages(this.filtredProducts)
      if (granitName === null || granitName === 0 || granitName === "" || granitName === "null") {
        // return filtered (monuments or accessories) with default graniteMain
        this.granit = null
        cleanSelect("granite")
        if (!isAccessory && this.color) {
          await this.onClickColor(this.color)
        } else {
          // Reset granit select
          if (!isAccessory) {
            this.filtredGranites = await [...this.granites].map(g => g.color === 0 ? ({ id: null, name: g.name }) : ({ id: `${g.reference}-${g.name}`, name: g.name }))
            createSelectByValue("select_granite", this.filtredGranites)
          }
          let products = this.oldproductsVisible.filter(prod => this.productsVisible.map(prodV => prodV.reference).includes(prod.reference))
          products.forEach(product => {
            const pdt = this.products.find(pdt => pdt.reference === product.reference && pdt.preconfId === product.preconfId)
            product.image = pdt.image
            product.gm = pdt.gm
            if (!isAccessory) {
              product.gs = pdt.gs
            }
            product.hide = pdt.hide
          })
          this.productsVisible = products
          this.sortedProducts()
          this.filtredProducts = this.productsVisible.slice(0, this.limit)
        }
      } else {
        if (isAccessory) {
          this.color = null
        }
        // apply selected granite to filtered (monuments or accessories)
        this.granit = granitName
        this.sortedProducts(false, false)
        products = [...this.productsVisible]
        this.loaded = false
        if (products.length === 0) this.loaded = true
        let i = 0
        let indexNotHide = 1
        this.filtredProducts = []
        for (let product of products) {
          product.prices = false
          // this.filtredProducts = []
          let colorGranit = []
          if (isAccessory) {
            colorGranit = product.granits && product.granits.length !== 0 ? product.granits.filter(granite => granite.reference === granit) : []
          } else {
            const monumentGranit = this.monuments_granits.find(g => g.token === product.granits)
            colorGranit = product.granits
              ? this.granites.filter(g1 => monumentGranit.granits.find(g2 => {
                if (g2.gs) {
                  return g2.gm === g1.reference && g2.gm === granit && g2.gs === product.gs
                } else {
                  return g2.gm === g1.reference && g2.gm === granit
                }
              }))
              : []
          }
          if (colorGranit.length !== 0) {
            product.image = ""
            let granitSecondaryreference = product.gs || null
            if (indexNotHide <= this.offset) {
              let img = await getImage(granit, product, "large", isAccessory, false, false, granitSecondaryreference)
              img = await img.image
              product.image = img
            } else {
              product.image = null
            }
            product.gm = granitName.split("-")[0]
            product.hide = false
            indexNotHide++
          } else {
            product.hide = true
          }
          i++
          if (i === products.length) {
            // this.filtredProducts = []
            const oldproductsVisible = JSON.parse(JSON.stringify(this.oldproductsVisible))
            this.oldproductsVisible = oldproductsVisible
            this.sortedProducts(products)
            this.filtredProducts = this.productsVisible.slice(0, this.limit)
            this.loaded = true
          }
        }
      }

      // GET PRICES
      await callGetPrices(this.filtredProducts, isAccessory)
      // GTM event
      if (callGtmEvent) {
        GtmController.displayResultSearchByGranit(this.filtredProducts, granit, isAccessory)
      }
      // end GTM event
    },
    async onClickColor(color, isAccessory = false) {
      let products = []
      this.limit = this.offset
      await loadingImages(this.filtredProducts)
      if (color === null || color === 0) {
        // Edit granit select
        if (!isAccessory) {
          this.filtredGranites = await [...this.granites].map(g => g.color === 0 ? ({ id: null, name: g.name }) : ({ id: `${g.reference}-${g.name}`, name: g.name }))
          createSelectByValue("select_granite", this.filtredGranites)
        }
        // return filtered (monuments or accessories) with default graniteMain
        this.color = null
        if (!isAccessory && this.granit) {
          await this.onClickGranit(this.granit, isAccessory)
          cleanSelect("granite", this.granit)
        } else {
          // apply selected color to filtered (monuments or accessories)
          let products = this.oldproductsVisible.filter(prod => this.productsVisible.map(prodV => prodV.reference).includes(prod.reference))
          products.forEach(product => {
            const pdt = this.products.find(pdt => pdt.reference === product.reference && pdt.preconfId === product.preconfId)
            product.image = pdt.image
            product.gm = pdt.gm
            if (!isAccessory) {
              product.gs = pdt.gs
            }
            product.hide = pdt.hide
          })
          this.productsVisible = products
        }
      } else {
        this.granit = null
        cleanSelect("granite")
        // Edit granit select
        if (!isAccessory) {
          this.filtredGranites = await [...this.granites].filter(g => g.color === color || g.color === 0).map(g => g.color === 0 ? ({ id: null, name: g.name }) : ({ id: `${g.reference}-${g.name}`, name: g.name }))
          createSelectByValue("select_granite", this.filtredGranites)
        }
        this.filtredProducts = []
        this.color = color
        this.sortedProducts(false, false)
        products = [...this.productsVisible]
        const size = "large"
        let n = 0
        let indexNotHide = 1
        this.loaded = false
        if (products.length === 0) this.loaded = true
        for (let product of products) {
          product.prices = false
          let colorGranit = []
          if (isAccessory) {
            colorGranit = product.granits && product.granits.length !== 0 ? product.granits.filter(granit => granit.color === color) : []
          } else {
            const monumentGranit = this.monuments_granits.find(g => g.token === product.granits)
            colorGranit = product.granits && monumentGranit.granits.length !== 0
              ? this.granites.filter(granit => granit.color === color && monumentGranit.granits.find(g => {
                if (g.gs) {
                  return g.gm === granit.reference && g.gs === product.gs
                } else {
                  return g.gm === granit.reference
                }
              }))
              : []
          }
          if (colorGranit.length !== 0) {
            product.image = ""
            // select random available granite
            let granit = colorGranit[Math.floor(Math.random() * colorGranit.length)]
            // load Images
            let granitSecondaryreference = !isAccessory ? product.gs : ""
            if (indexNotHide <= this.offset) {
              let img = await getImage(granit.reference, product, size, isAccessory, false, false, granitSecondaryreference)
              product.image = img.image
            } else {
              product.image = null
            }
            product.gm = granit.reference
            product.hide = false
            indexNotHide++
          } else {
            product.hide = true
          }
          n++
          if (n === products.length) {
            this.loaded = true
            const oldproductsVisible = JSON.parse(JSON.stringify(this.oldproductsVisible))
            this.sortedProducts()
            this.productsVisible = products
            this.oldproductsVisible = oldproductsVisible
            // this.onsearch = false
          }
        }
      }

      this.sortedProducts()
      this.filtredProducts = this.productsVisible.slice(0, this.limit)
      // GET PRICES
      await callGetPrices(this.filtredProducts, isAccessory)
    },
    async onClickCategoryAcc(categorie, callGtmEvent = false) {
      this.limit = this.offset
      let accessories = [...this.oldproductsVisible]
      // filter accessories by kind (category)
      if (categorie === null || categorie === "" || categorie === undefined) {
        this.category = null
        cleanSelect("category", this.category)
        this.productsVisible = accessories
      } else {
        this.category = categorie
        this.productsVisible = accessories.filter(accessory => accessory.kind === categorie)
      }
      // filter accessories by keywords
      if (this.searchkeys.length > 0 && !this.onsearch) {
        this.onsearch = true
        this.onSearchACC(this.searchkeys)
      }
      // apply selected (granit or color)
      if (this.granit != null) {
        this.onClickGranit(this.granit, true)
      } else if (this.color != null) {
        this.onClickColor(this.color, true)
      }
      this.sortedProducts()
      this.filtredProducts = this.productsVisible.slice(0, this.limit)

      // GTM
      if (callGtmEvent) {
        GtmController.displayResultSearchByCategory(this.filtredProducts, categorie, true)
      }
      // GTM end

      // GET PRICES
      callGetPrices(this.filtredProducts, true)
    },
    isCompared(reference) {
      return !!this.monumentsToCompare.find(m => m.reference === reference)
    },
    comparatorLayoutSizes() {
      const layouts = this.filtredLayouts
      const selectedLayout = layouts.find(layoutObj => layoutObj.id === this.comparatorLayout)
      return selectedLayout.name.match(/ - (.+)/)[1]
    },
    getGraniteName(reference) {
      const granit = this.granites.find(g => g.reference === reference)
      return granit ? granit.name : ""
    }
  })

  Alpine.store("productGranits", {
    product: null,
    preconfigSec: null,
    productIsAcc: false,
    filter: {
      granit: null,
      color: null
    },
    products: [],
    filtredColor: null,
    isHide: true,
    colorSecondary: false,
    matchingColorsPrimary: [],
    matchingColorsSecondary: [],
    productsFiltered: [],
    productsList: [],
    matchingcolor: false,
    bicoloreMonument: null,
    granits: [],
    offset: 20,
    limit: 20,
    colorsSorted: [],
    message: false,
    productslimit: 75,
    productIndex: 0,
    setupScrollListener() {
      // this.sortedProducts()
      document.querySelector(".scroll-load")?.addEventListener(
        "scroll",
        debounce(async (e) => {
          const scrollHeight = e.target.scrollHeight
          const scrollTop = e.target.scrollTop
          const clientHeight = e.target.clientHeight
          if (
            scrollTop + clientHeight >= scrollHeight / (this.granits.length / this.productsFiltered.length) &&
            this.filter.color === null && this.filter.granit == null) {
            if (this.productsFiltered.length < this.productslimit) {
              this.limit += this.offset
              this.productsFiltered = this.products.slice(0, this.limit)
            }
            const products = this.productsFiltered.filter(prod => !prod.prices)
            if (products.length) {
              await this.getprices(products)
              // // GTM
              GtmController.displayGranitsOfProduct(this.productsFiltered, "MNT", [], this.offset)
              // // END GTM
            }
          }
          if (
            scrollTop + clientHeight >= scrollHeight && this.productsFiltered.length >= this.productslimit && this.granits.length > this.productslimit &&
            !this.message && this.filter.color === null && this.filter.granit == null) {
            this.message = true
          } else if (this.message) {
            this.message = false
          }
        }, 100)
      )
    },
    async addProduct(product, granit) {
      // let granitSecondaryreference = product.graniteSecondary.reference
      let granitSecondary = granit.granitSecondary
      if (!this.colorSecondary) {
        this.colorSecondary = true
      }
      if (granitSecondary === "" || granitSecondary === null) {
        granitSecondary = Alpine.store("catalogs").granites.find(granite => granite.reference === product.gs)
      }

      // granitSecondaryreference = granitSecondary.reference
      product.image = ""

      // granit
      product.gm = granit.reference
      product.gs = granitSecondary.reference
      // color
      product.color = granit.color
      product.secondarycolor = granitSecondary.color
      let colorProduct = Alpine.store("granit").colors.find(c => c.id === granit.color)
      product.colorreference = colorProduct.backcolor
      colorProduct = Alpine.store("granit").colors.find(c => c.id === granitSecondary.color)
      product.secondarycolorreference = colorProduct.backcolor
      product.prices = false
      product.noPrice = false
    },
    async renderProducts(firstload = false) {
      // this.sortedProducts()
      let granits = this.granits.filter(granit => {
        if (granit.granitSecondary !== null) {
          return true
        }
        return false
      })
      if (this.productsList.length !== 0) {
        this.productsList.forEach(product => {
          granits = granits.filter(granit => !(product.gs === granit.granitSecondary.reference && product.gm === granit.reference))
        })
      }
      this.colorsSorted = this.colorsSorted.filter(color => granits.filter(granit => color === `${granit.color}/${granit.granitSecondary.color}`).length)

      // if (granits.length > 30 && granits.length > this.colorsSorted.length) {
      return new Promise((resolve, reject) => {
        this.colorsSorted.forEach(async (color, index, array) => {
          let img
          let product = JSON.parse(JSON.stringify(this.product))
          let granitscolor = granits.filter(granit => {
            if (granit.granitSecondary !== null) {
              return color === `${granit.color}/${granit.granitSecondary.color}`
            }
            return false
          })

          if (granitscolor.length === 0) return
          const granit = granitscolor[Math.floor(Math.random() * granitscolor.length)]
          this.addProduct(product, granit)
          if (firstload && this.productIndex < this.productslimit) {
            product.image = ""
            if (Alpine.store("gpg").preconfigSec) {
              product.preconfigId = Alpine.store("gpg").preconfigSec.reference
              img = await getImage(granit.reference, product, "large", this.productIsAcc, granit.reference, Alpine.store("gpg").preconfigSec, product.gs)
            } else {
              img = await getImage(granit.reference, product, "large", this.productIsAcc, false, false, product.gs)
            }
            product.image = img.image
            if (this.productIndex < this.productslimit) {
              this.products.push(product)
              if (this.productIndex < 20) {
                this.productsFiltered.push(product)
              }
            }
          }
          this.productIndex++

          // this.productsList.push(product)
          product.prices = false
          product.noPrice = false
          // if (i === this.colorsSorted.length && firstload && this.productsFiltered.length && this.productsFiltered.length < 9) {
          //   // this.getprices(this.products)
          //   this.renderProducts(true)
          // }
          if (firstload && this.productIndex === this.productslimit) {
            index = array.length
            resolve()
          } else if (index === (array.length - 1) && this.productIndex < this.productslimit) {
            index = 0
            await this.renderProducts(true)
          }
          // else if (firstload && array.length === index){
          // }
        })
        // } else {
        //   granits.forEach(granit => {
        //     let product = JSON.parse(JSON.stringify(this.product))
        //     this.addProduct(product, granit)
        //     this.productsList.push(product)
        //     product.prices = false
        //     product.noPrice = false
        //   })
        // }
      }).then(async () => {
        await this.getprices(this.productsFiltered)
        // GTM
        GtmController.displayGranitsOfProduct(this.productsFiltered, "MNT")
        // END GTM
        this.setupScrollListener()
      })
    },
    async getprices(products, filter = false, isAccessory = false) {
      if (Alpine.store("gpg").preconfigSec) {
        if (filter) {
          await callGetPreconfigs(products, true, this.bicoloreMonument)
        } else {
          await getPreconfigsPrice(products, true).then(
            result => {
              if (result.prices !== undefined) {
                result.prices.forEach(priceItem => {
                  let productIndex = this.productsFiltered.findIndex(prod => prod.gm === priceItem.granit && prod.gs === priceItem.graniteSecondary)
                  if (productIndex !== -1) {
                    let modifiedProduct = { ...this.productsFiltered[productIndex], prices: priceItem.prices }
                    this.productsFiltered[productIndex] = modifiedProduct
                    this.productsFiltered[productIndex].noPrice = false
                    if (priceItem.prices.buying === 0) {
                      this.productsFiltered[productIndex].noPrice = true
                    }
                  }
                })
              } else {
                Alpine.store("catalogs").error = true
              }
            }
          )
        }
      } else {
        await callGetPrices(products, isAccessory, true, true)
      }
    },
    async initProducts(type) {
      Alpine.store("catalogs").error = false
      this.product = null
      this.message = false
      this.filtredColor = null
      this.granits = []
      this.bicoloreMonument = null
      this.productIndex = 0
      this.filter = {
        granit: null,
        color: null,
        hovercolor: null
      }
      switch (type) {
        case "ACC":
          this.productIsAcc = true
          this.product = JSON.parse(JSON.stringify(Alpine.store("catalogs").accessory))
          break
        case "MNT":
          this.product = JSON.parse(JSON.stringify(Alpine.store("catalogs").monument))
          this.product.hide = false
          break
      }
      this.products = []
      this.productsFiltered = []
      this.productsList = []
      this.isHide = true
      this.matchingcolor = false

      let productsLoop
      if (this.product === null) return
      let isBicolore
      isBicolore = this.product.bicoloreMonument
      if (!isBicolore) {
        isBicolore = this.product.isBicolore
      }
      if (!this.productIsAcc && isBicolore) {
        const targetMonument = Alpine.store("catalogs").isBicolorProducts.find(item => item.productId === this.product.bicoloreMonument)
        if (targetMonument) {
          const monumentGranit = Alpine.store("catalogs").monuments_granits.find(mg => mg.token === targetMonument.granits)
          this.granits = JSON.parse(JSON.stringify(monumentGranit.granits))
          this.bicoloreMonument = true
        } else {
          const monumentGranit = Alpine.store("catalogs").monuments_granits.find(mg => mg.token === this.product.granits)
          this.granits = JSON.parse(JSON.stringify(monumentGranit.granits))
        }

        this.granits = this.granits.map(granite => {
          const graniteSecond = Alpine.store("catalogs").granites.find(granit => granite.gs === granit.reference)
          const graniteMain = Alpine.store("catalogs").granites.find(granit => granite.gm === granit.reference)
          granite.granitSecondary = graniteSecond
          granite.color = graniteMain.color
          granite.colororder = graniteMain.colororder
          granite.name = graniteMain.name
          granite.reference = graniteMain.reference
          return granite
        })
        this.colorsSorted = []
        let colors = Alpine.store("catalogs").matching_color_list
        this.colorsSorted = arrayRand(colors)

        productsLoop = new Promise((resolve, reject) => {
          this.matchingcolor = true
          const productCount = this.renderProducts(true)

          if (this.productIndex >= this.productslimit && productCount) { resolve() }
          // this.matchingcolor = true
          // this.renderProducts(true)
          // this.setupScrollListener()
        })
      } else {
        productsLoop = new Promise((resolve, reject) => {
          if (!this.productIsAcc) {
            const monumentGranit = Alpine.store("catalogs").monuments_granits.find(mg => mg.token === this.product.granits)
            this.granits = monumentGranit.granits
          } else {
            this.granits = this.product.granits
          }
          let granits
          if (!this.productIsAcc) {
            granits = this.granits.filter((a, i) => this.granits.findIndex(b => b.gm === a.gm) === i)
              .map(granite => granite.gm)
          } else {
            granits = this.granits.filter((a, i) => this.granits.findIndex(b => b.reference === a.reference) === i)
              .map(granite => granite.reference)
          }
          this.granits = Alpine.store("catalogs").granites.filter(granite => granits.includes(granite.reference))
          this.granits.forEach(async granit => {
            let product = JSON.parse(JSON.stringify(this.product))
            // img
            let img
            product.image = ""
            let graniteSecondaryRef = ""
            if (Alpine.store("gpg").preconfigSec) {
              if (!this.productIsAcc) {
                graniteSecondaryRef = product.gs
              }
              product.preconfigId = Alpine.store("gpg").preconfigSec.reference
              img = await getImage(granit.reference, product, "large", this.productIsAcc, granit.reference, Alpine.store("gpg").preconfigSec, graniteSecondaryRef)
            } else {
              if (!this.productIsAcc) {
                graniteSecondaryRef = product.gs
              }
              img = await getImage(granit.reference, product, "large", this.productIsAcc, false, false, graniteSecondaryRef)
            }
            product.image = img.image
            product.fullimage = img.image
            // granit
            product.gm = granit.reference
            // order granit by color
            product.maincolororder = granit.colororder
            // color
            product.color = granit.color
            //
            this.products.push(product)
            this.productsFiltered.push(product)
            product.prices = false
            product.noPrice = false
            // LOPP FINISHED
            if (this.productsFiltered.length === this.granits.length) { resolve() }
          })
        })
      }

      const isAccessory = type === "ACC"
      productsLoop.then(async () => {
        if (!this.matchingcolor) {
          // GET PRICES
          await this.getprices(this.productsFiltered, isAccessory)
        }
        // GTM
        GtmController.displayGranitsOfProduct(this.products, type)
        // END GTM
      })
    },
    // scrollListener() {
    //   document.querySelector(".scroll-load")?.addEventListener(
    //     "scroll",
    //     debounce(async (e) => {
    //       const scrollHeight = e.target.scrollHeight
    //       const scrollTop = e.target.scrollTop
    //       const clientHeight = e.target.clientHeight
    //       if (scrollTop + clientHeight >= scrollHeight / 3 && this.limit < this.products.length) {
    //         this.limit += this.offset
    //         this.productsFiltered = this.products.slice(0, this.limit)
    //         // load Images
    //         this.productsFiltered.slice(this.limit - this.offset, this.limit).forEach(async p => {
    //           let img
    //           if (Alpine.store("gpg").preconfigSec) {
    //             p.preconfigId = Alpine.store("gpg").preconfigSec.reference
    //             img = await getImage(p.graniteMain.reference, p, "large", this.productIsAcc, p.graniteMain.reference, Alpine.store("gpg").preconfigSec)
    //           } else {
    //             img = await getImage(p.graniteMain.reference, p, "large", this.productIsAcc)
    //           }
    //           p.image = img.image
    //           p.fullimage = img.image
    //         })
    //       }
    //     }, 100)
    //   )
    // },
    async onFilterByGranit(granit) {
      if (granit === "") {
        this.reset()
        return 0
      }
      this.message = false
      const granitRef = granit.slice(0, 2)
      this.isHide = true
      this.filter = {
        granit: granitRef,
        color: null,
        hovercolor: null
      }
      this.productsFiltered = []
      this.filtredColor = null
      if (!this.productIsAcc && this.matchingcolor) {
        const granites = this.granits.filter(granit => granit.reference === granitRef || granit.granitSecondary?.reference === granitRef)
        new Promise((resolve, reject) => {
          granites.forEach(async granit => {
            let product = JSON.parse(JSON.stringify(this.product))
            // img
            let img
            let granitSecondaryreference = product.gs
            let granitSecondary = granit.granitSecondary
            if (!this.colorSecondary) {
              this.colorSecondary = true
            }
            product.gs = granitSecondary.reference
            product.secondarycolor = granitSecondary.color
            granitSecondaryreference = granitSecondary.reference

            if (Alpine.store("gpg").preconfigSec) {
              product.preconfigId = Alpine.store("gpg").preconfigSec.reference
              img = await getImage(granit.reference, product, "large", this.productIsAcc, granit.reference, Alpine.store("gpg").preconfigSec, granitSecondaryreference)
            } else {
              img = await getImage(granit.reference, product, "large", this.productIsAcc, false, false, granitSecondaryreference)
            }
            product.image = img.image
            product.fullimage = img.image
            // granit
            product.gm = granit.reference
            // color
            product.color = granit.color
            //
            // this.products.push(product)
            this.productsFiltered.push(product)
            product.prices = false
            product.noPrice = false
            // LOPP FINISHED
            if (this.productsFiltered.length === granites.length) { resolve() }
          })
        }).then(async () => {
          await loadingImages(this.filtredProducts, "#catalogs")
          // GET PRICES
          await this.getprices(this.productsFiltered)
          // GTM
          const filter = [{ name: "granit", value: `granit_${granit.slice(0, 2)}` }]
          const type = this.productIsAcc ? "ACC" : false
          GtmController.displayGranitsOfProduct(this.productsFiltered, type, filter)
          // END GTM
          // const isAccessory = type === "ACC"
        })
      } else {
        await loadingImages(this.productsFiltered, "#catalogs")
        this.productsFiltered = this.products.filter(p => p.gm === granitRef)

        // GET PRICES
        if (Alpine.store("gpg").preconfigSec) {
          await callGetPreconfigs(this.productsFiltered, true)
          return
        }
        await callGetPrices(this.productsFiltered, this.productIsAcc, true, true)
        // GTM
        const filter = [{ name: "granit", value: `granit_${granit.slice(0, 2)}` }]
        const type = this.productIsAcc ? "ACC" : false
        GtmController.displayGranitsOfProduct(this.productsFiltered, type, filter)
        // END GTM
      }
    },
    matchingColor(color) {
      this.filter.hovercolor = color
      closeAllSelect(null)
      if (this.product && this.colorSecondary && this.matchingcolor) {
        let colors = Alpine.store("catalogs").matching_colors // eslint-disable-line no-undef
        this.matchingColorsSecondary = Alpine.store("granit").colors.filter(c => c.id !== color && colors.includes(`${c.id}/${color}`)).sort((c1, c2) => c1.order - c2.order)
        this.matchingColorsPrimary = Alpine.store("granit").colors.filter(c => c.id !== color && colors.includes(`${color}/${c.id}`)).sort((c1, c2) => c1.order - c2.order)
        const colorparent = Alpine.store("granit").colors.find(c => c.id === color)
        this.isHide = false
        setTimeout(() => {
          document.getElementsByClassName("colorproductSelected").forEach(button => {
            button.style.background = `repeating-conic-gradient( from 40deg, ${colorparent.backcolor} 0deg calc(3.6deg * 50), ${colorparent.backcolor} 50deg 360deg )`
          })
          document.getElementsByClassName("colorproductsSecondary").forEach(button => {
            button.style.background = `repeating-conic-gradient( from 40deg, ${colorparent.backcolor} 0deg calc(3.6deg * 50), ${button.id} 50deg 360deg )`
          })
          document.getElementsByClassName("colorproductsPrimary").forEach(button => {
            button.style.background = `repeating-conic-gradient( from 40deg, ${button.id} 0deg calc(3.6deg * 50), ${colorparent.backcolor} 50deg 360deg )`
          })
        }, 0)
      }
      document.querySelector(".modal-body").addEventListener("click", function (e) {
        if (!e.target.classList.contains("colorFilter") && !e.target.classList.contains("productColorFilter")) {
          Alpine.store("productGranits").isHide = true
        }
      })
    },
    async onFilterByColor(color, productfilter = false) {
      // this.filter = {
      //   granit: null,
      //   color: color
      // }
      this.filtredColor = color
      this.filter.granit = null
      let monocolor = false
      this.message = false
      if (color.toString().split("/").length > 1) {
        if (parseInt(color.toString().split("/")[0]) === parseInt(color.toString().split("/")[1])) {
          monocolor = true
        }
        if (productfilter) {
          this.filter.color = parseInt(color.toString().split("/")[0])
          this.filter.hovercolor = this.filter.color
          if (this.filter.color === parseInt(color.toString().split("/")[1])) {
            this.filtredColor = this.filter.color
          }
        } else {
          this.filter.color = this.filter.hovercolor
        }
        this.filter.colorMain = parseInt(color.toString().split("/")[0])
        this.filter.colorSecondary = parseInt(color.toString().split("/")[1])
      } else {
        this.filter.color = color
        this.filter.colorMain = color
        this.filter.colorSecondary = color
      }
      // this.filter.hovercolor = this.filter.color
      cleanSelect("granite")
      this.productsFiltered = []
      if (this.product && this.colorSecondary && this.matchingcolor) {
        Alpine.store("productGranits").isHide = false
        if (this.filter.colorMain !== null) {
          this.matchingColor(this.filter.color)
        }
        // this.isHide = false
        // this.productsFiltered = this.products.filter(p => p.color === this.filter.colorMain && p.secondarycolor === this.filter.colorSecondary)
        let granites = this.granits.filter(granit => granit?.color === this.filter.colorMain && granit.granitSecondary !== "" && granit.granitSecondary !== null && granit.granitSecondary.color === this.filter.colorSecondary)
        if (monocolor) {
          granites = granites.filter(granit => granit.reference === granit.granitSecondary.reference)
        }

        new Promise((resolve, reject) => {
          granites.forEach(async granit => {
            let product = JSON.parse(JSON.stringify(this.product))
            // img
            let img
            let granitSecondary = granit.granitSecondary
            if (!this.colorSecondary) {
              this.colorSecondary = true
            }
            product.gs = granitSecondary.reference
            product.secondarycolor = granitSecondary.color
            // granit
            product.gm = granit.reference
            // order granit by color
            product.maincolororder = granit.colororder
            product.secondarycolororder = granitSecondary.colororder
            // images
            product.image = ""
            product.fullimage = ""
            // this.productsFiltered.push(product)
            if (Alpine.store("gpg").preconfigSec) {
              product.preconfigId = Alpine.store("gpg").preconfigSec.reference
              img = await getImage(product.gm, product, "large", this.productIsAcc, product.gm, Alpine.store("gpg").preconfigSec, product.gs)
            } else {
              img = await getImage(product.gm, product, "large", this.productIsAcc, false, false, product.gs)
            }
            product.image = img.image
            product.fullimage = img.image
            // color
            product.color = granit.color
            //
            this.productsFiltered.push(product)
            // products.push(product)
            product.prices = false
            product.noPrice = false
            // LOPP FINISHED
            if (this.productsFiltered.length === granites.length) { resolve() }
          })
        }).then(async () => {
          await loadingImages(this.filtredProducts, "#catalogs")
          this.productsFiltered = this.productsFiltered.sort((p1, p2) => {
            return p1.secondarycolororder - p2.secondarycolororder
          })
          this.productsFiltered = this.productsFiltered.sort((p1, p2) => {
            return p1.maincolororder - p2.maincolororder
          })
          // let isAccessory = false

          // GET PRICES
          await this.getprices(this.productsFiltered)
          // GTM
          if (color) {
            let colorNames
            if (color.toString().includes("/")) {
              let colors = color.split("/")
              colorNames = colors
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(color => GtmController.staticGetColorName(parseInt(color))).join("/")
            } else {
              colorNames = GtmController.staticGetColorName(parseInt(color))
            }
            const filter = [{ name: "color", value: colorNames }]
            const type = this.productIsAcc ? "ACC" : false
            GtmController.displayGranitsOfProduct(this.productsFiltered, type, filter)
          }
          // END GTM
        })
      } else {
        await loadingImages(this.productsFiltered, "#catalogs")
        this.productsFiltered = this.products.filter(p => p.color === color).sort((p1, p2) => {
          return p1.maincolororder - p2.maincolororder
        })
        let isAccessory = false
        // GTM
        if (color) {
          let colorNames
          if ((color.toString().includes("/"))) {
            let colors = color.split("/")
            colorNames = colors
              .filter((value, index, self) => self.indexOf(value) === index)
              .map(color => GtmController.staticGetColorName(parseInt(color))).join("/")
          } else {
            colorNames = GtmController.staticGetColorName(parseInt(color))
          }
          const filter = [{ name: "color", value: colorNames }]
          const type = this.productIsAcc ? "ACC" : false
          isAccessory = type === "ACC"
          GtmController.displayGranitsOfProduct(this.productsFiltered, type, filter)
        }
        // END GTM

        // GET PRICES
        if (Alpine.store("gpg").preconfigSec) {
          callGetPreconfigs(this.productsFiltered, true)
          return
        }
        callGetPrices(this.productsFiltered, isAccessory, true, true)
      }
    },
    async reset() {
      this.message = false
      this.filter = {
        granit: null,
        color: null,
        hovercolor: null
      }
      this.filtredColor = null
      this.productsFiltered = []
      await loadingImages(this.productsFiltered, "#catalogs")
      this.productsFiltered = [...this.products]
      this.matchingColorsFiltered = []
      // this.isHide = true
      // this.matchingcolor = false
      cleanSelect("granite")

      // GET PRICE
      if (Alpine.store("gpg").preconfigSec) {
        await callGetPreconfigs(this.productsFiltered, true)
        return
      }
      await callGetPrices(this.productsFiltered, false, true, true)
      // END PRICES

      // GTM
      const type = this.productIsAcc ? "ACC" : false
      GtmController.displayGranitsOfProduct(this.productsFiltered, type)
      // END GTM
    }
  })

  Alpine.store("news", {
    async init() {
      const elementTags = document.getElementById("news")
      if (elementTags) {
        await this.getTags() // Get tags
        this.loaded = true
      }
    },
    newsTags: [],
    async getTags() {
      cleanSelect("categories")
      this.loaded = false
      await getNewsTags().then(
        async result => {
          if (result.error !== undefined) {
            this.error = true
          } else {
            this.newsTags = result
            this.loaded = true
            createSelectByValue("select_categories", this.newsTags)
          }
        }

      )
    },
    filterByTag(value) {
      let url = new URL(window.location.href)
      let searchParams = url.searchParams
      value !== "-1" ? searchParams.set("tag", value) : searchParams.delete("tag")
      window.location.href = url.toString()
    }
  })
})

function loadingImages(products = [], ...except) {
  for (let product of products) {
    product.image = null
  }
  document.getElementsByClassName("product-image")?.forEach(
    image => {
      let isValid = true
      except.forEach(selector => {
        if (document.querySelector(selector)?.contains(image)) {
          isValid = false
        }
      })
      if (isValid) {
        image.loading = "lazy"
        image.classList.add("loading")
      }
    }
  )
}
function debounce(func, delay) {
  let timer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}
async function getData() {
  const monumentsEndpoint = document.getElementById("result").getAttribute("data-monuments")
  const data = await get(monumentsEndpoint).then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}

async function getDataStocks() {
  const data = await get("/stocks_list").then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}

async function getPreconfigsPrice(products, isGranits = false, bicoloreMonument = null) {
  if (products.length === 0) {
    return false
  }
  let productsToFetch
  if (isGranits) {
    productsToFetch = products
      .map(product => ({
        reference: "",
        preconfId: product.preconfigId,
        graniteMainReference: product.gm,
        graniteSecondaryReference: product.gs,
        hide: false
      }))
  } else {
    productsToFetch = products
      .map(product => ({
        reference: "",
        preconfId: product.reference,
        graniteMainReference: product.gm,
        graniteSecondaryReference: product.gs,
        hide: false
      }))
  }
  let url = "/monuments_list/prices"
  let data = await post(url, { products: productsToFetch, isPreConfig: true, layout: Alpine.store("catalogs").layout, bicoloreMonument: bicoloreMonument }).then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}

async function getPrices(products, isAccessory = false, allGranit = false, bicoloreMonument = null) {
  let productsToFetch = products.map(product => ({
    reference: product.reference,
    graniteMainReference: product.gm,
    graniteSecondaryReference: product.gs,
    preconfId: product.preconfId,
    hide: product.hide
  }))
  if (!allGranit) {
    // Filter out products that already have cached prices
    productsToFetch = products
      .filter(product => {
        const ref = product.reference
        const graniteRef = product.gm || ""
        const graniteSecondaryRef = product.gs || ""
        if (Alpine.store("catalogs").layout) {
          return !Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${Alpine.store("catalogs").layout}:${graniteSecondaryRef}`]
        }
        return !Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${graniteSecondaryRef}`]
      })
      .map(product => ({
        reference: product.reference,
        graniteMainReference: product.gm,
        graniteSecondaryReference: product.gs,
        preconfId: product.preconfId
      }))
    // If all products have cached prices, return the cache
    if (productsToFetch.length === 0) {
      return {
        prices: products.map(product => {
          const ref = product.reference
          const graniteRef = product.gm || ""
          const graniteSecondaryRef = product.gs || ""
          if (Alpine.store("catalogs").layout) {
            return Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${Alpine.store("catalogs").layout}:${graniteSecondaryRef}`]
          }
          return Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${graniteSecondaryRef}`]
        })
      }
    }
  }
  let url = "/monuments_list/prices"
  if (isAccessory) {
    url = "/accessories_list/prices"
  }
  let data = await post(url, { products: productsToFetch, layout: Alpine.store("catalogs").layout, bicoloreMonument: bicoloreMonument }).then(response => {
    return response.json().then(data => {
      return data
    })
  })

  if (!allGranit && data.length > 0) {
    // Update the cache with the fetched prices
    data.prices.forEach(priceItem => {
      if (Alpine.store("catalogs").layout) {
        Alpine.store("catalogs").priceCache[`${priceItem.reference}:${priceItem.granit}:${Alpine.store("catalogs").layout}:${priceItem.graniteSecondary}`] = priceItem
      } else {
        Alpine.store("catalogs").priceCache[`${priceItem.reference}:${priceItem.granit}:${priceItem.graniteSecondary}`] = priceItem
      }
    })
  }

  return data
}

function callGetPreconfigs(preconfigs, isGranits, bicoloreMonument) {
  getPreconfigsPrice(preconfigs, isGranits).then(
    result => {
      if (result.prices !== undefined) {
        result.prices.forEach(priceItem => {
          let productIndex = Alpine.store("productGranits").productsFiltered.findIndex(prod => prod.gm === priceItem.granit && prod.gs === priceItem.graniteSecondary)
          if (productIndex !== -1) {
            let modifiedProduct = { ...Alpine.store("productGranits").productsFiltered[productIndex], prices: priceItem.prices }
            Alpine.store("productGranits").productsFiltered[productIndex] = modifiedProduct
          }
        })
      } else {
        Alpine.store("catalogs").error = true
      }
    }
  )
}

async function callGetPrices(products, isAccessory = false, notFirstSearch = true, granitSearch = false, noSorting = false) {
  if (!isAccessory) {
    const elementCata = document.getElementById("result")
    const catalogTypeValue = elementCata.getAttribute("data-catalog-type")
    let userCanOrder = elementCata.getAttribute("data-can-order")
    userCanOrder = userCanOrder === "false" ? false : Boolean(userCanOrder)

    if (catalogTypeValue === "pro" && userCanOrder) {
      if (notFirstSearch) {
        products.forEach(product => {
          const ref = product.reference
          const graniteRef = product.gm || ""
          const graniteSecondaryRef = product.gs || ""
          let cachedPrice = 0
          if (Alpine.store("catalogs").layout) {
            cachedPrice = Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${Alpine.store("catalogs").layout}:${graniteSecondaryRef}`]
          } else {
            cachedPrice = Alpine.store("catalogs").priceCache[`${ref}:${graniteRef}:${graniteSecondaryRef}`]
          }
          if (cachedPrice) {
            product.prices = cachedPrice.prices
          }
        })
      }
      await getPrices(products, isAccessory).then(
        result => {
          if (result.prices !== undefined) {
            result.prices.forEach(priceItem => {
              let product = []
              if (!granitSearch) {
                product = Alpine.store("catalogs").productsVisible.find(prod => prod.reference === priceItem.reference && prod.preconfId === priceItem.preconfId)
              } else {
                product = Alpine.store("productGranits").productsFiltered.find(prod => prod.reference === priceItem.reference && prod.gm === priceItem.granit && prod.gs === priceItem.graniteSecondary)
              }
              if (product) {
                product.noPrice = false
                if (priceItem.prices === 0 || priceItem.prices.buying === 0) {
                  product.noPrice = true
                }
                product.prices = priceItem.prices
                if (granitSearch) {
                  Alpine.store("productGranits").productsFiltered.forEach(prod => {
                    if (prod.reference === priceItem.reference && prod.gm === priceItem.granit && prod.gs === priceItem.graniteSecondary && !prod.prices) {
                      product.noPrice = false
                      if (priceItem.prices === 0 || priceItem.prices.buying === 0) {
                        product.noPrice = true
                      }
                      prod.prices = priceItem.prices
                    }
                  })
                }
              }
            })
            if (!granitSearch && !noSorting) {
              Alpine.store("catalogs").sortedProducts(Alpine.store("catalogs").productsVisible, true, true)
            }
          } else {
            Alpine.store("catalogs").error = true
          }
          // Set priceLoading to false to hide the loader
          Alpine.store("catalogs").priceLoading = false
        }
      ).catch(error => {
        console.error("An error occurred while fetching prices: ", error)
        Alpine.store("catalogs").error = true
      })
    }
  }
}

async function getDataGra() {
  const granitesEndpoint = document.getElementById("granites-result").getAttribute("data-granites")
  const data = await get(granitesEndpoint).then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}
async function getDataColors() {
  const data = await get("/colors/color_list").then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}

async function getDataAcc() {
  const accessoriesEndpoint = document.getElementById("accessories-result").getAttribute("data-accessories")
  const data = await get(accessoriesEndpoint).then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

export async function getImage(granit, monument, size, isAccessory, preconfSecGranit, preconfigSec = false, granitSecondary = "") {
  if (isAccessory) {
    const cachekey = `ACC:${monument.reference}${granit}:${monument.tstamp}`
    const data = `monument=${monument.reference}${granit}&hasFrame=false&size=${size}&cache_key=${cachekey}`
    const result = await get(`/monument_picture?${data}`).then(response => {
      return response.json().then(data => {
        return data
      })
    })
    return result
  }
  let secondarygranit = ""
  let graniteMinor = ""
  if (!isAccessory) {
    if (granitSecondary !== "") {
      secondarygranit = granitSecondary
    } else {
      secondarygranit = monument.gs
    }
    if (secondarygranit !== "") {
      graniteMinor = `:${secondarygranit}`
    }
  }
  if (monument.preconfId || preconfigSec) {
    const preconfId = preconfigSec
      ? preconfigSec.reference
      : monument.preconfId

    let cachekey
    let granitRef

    if (preconfigSec) {
      if (preconfSecGranit) {
        cachekey = `${preconfigSec.cachekey}:${preconfSecGranit}${graniteMinor}`
        granitRef = `&granite=${preconfSecGranit}`
      } else {
        cachekey = `${preconfigSec.cachekey}:${preconfigSec.gm}${graniteMinor}`
        granitRef = `&granite=${preconfigSec.gm}`
      }
    } else {
      cachekey = `${monument.cachekey}:${granit}${graniteMinor}`
      granitRef = `&granite=${granit}`
    }

    const data = `monument=${monument.productId}&hasFrame=${monument.hasFrame}&graniteSecondary=${secondarygranit}&preconfId=${preconfId}${granitRef}&size=${size}&cache_key=${cachekey}`
    const result = await get(`/monument_picture?${data}`).then(response => {
      return response.json().then(data => {
        return data
      })
    })
    return result
  } else {
    const cachekey = `${monument.cachekey}:${granit}${graniteMinor}`
    const data = `monument=${monument.productId}${granit}&hasFrame=${monument.hasFrame}&graniteSecondary=${secondarygranit}&size=${size}&cache_key=${cachekey}`
    const result = await get(`/monument_picture?${data}`, { method: "GET" }).then(response => {
      return response.json().then(data => {
        return data
      })
    })
    return result
  }
}

function initsearchInput(keys) {
  let optionmore = null
  let option2 = document.createElement("li")
  option2.classList.add("select2-results__option")
  option2.classList.add("vide")
  $("#search").select2({
    placeholder: $("#search").attr("placeholder"), /* eslint-env jquery */
    minimumResultsForSearch: 15,
    tags: true,
    templateResult: formatState
  }).val(keys)
  $(".select2-search__field").keydown(function (e) {
    setTimeout(() => {
      let element = null
      let lineElements = 0
      let line = 0
      // loop of select options
      document.querySelectorAll(".select2-results__option").forEach(select => {
        if (select.classList.contains("more")) select.classList.remove("select2-results__option--selected")
        // New line ++
        if (select.offsetLeft === 7 || select.offsetLeft === 10) line++
        // if it's the last line(3th one), set option(select) as the last element to add btn(see more) after it
        if (line === 3) {
          if (select.classList.contains("select2-results__option--selected")) return
          if (select.classList.contains("select2-results__option--disabled")) return
          element = select
          lineElements++
        }
      })
      // if more than 3 line and the last element is not see more btn, we setting btn
      if (line > 3) {
        if (optionmore && element && !element.classList.contains("more")) {
          element.parentNode.insertBefore(optionmore, element)
          if (!(element.offsetLeft === 7 || element.offsetLeft === 10) || lineElements === 1) {
            if (optionmore.parentNode) {
              optionmore.parentNode.removeChild(optionmore)
              element.parentNode.insertBefore(optionmore, element.nextSibling)
            }
          }
          optionmore.parentNode.insertBefore(option2, optionmore.nextSibling)
          // if (optionmore && element) {
          //   if (i > n + 1) {
          //     div.style.width = parseInt(margin) + "px"
          //     element?.parentNode.insertBefore(optionmore, element)
          //     optionmore.parentNode.insertBefore(option2, optionmore.nextSibling)
          //   }
          // }
        }
      } else {
        // search if there is a btn see more => hide it
        document.querySelectorAll(".select2-results__option").forEach(select => {
          if (select.classList.contains("more") || select.id.includes("-more")) {
            select.style.display = "none"
          }
        })
      }
    }, 0)

    if (e.keyCode === 8 || e.keyCode === 46) {
      if ($(this).val().length === 0) {
        e.stopPropagation()
        e.preventDefault()
      }
    }
  })

  $(".select2-search__field").on("focus", function (e) {
    setTimeout(() => {
      let element = null
      let lineElements = 0
      let newli = null
      let line = 0
      // loop of select options
      document.querySelectorAll(".select2-results__option")?.forEach(select => {
        if (select.id.includes("-more")) select.classList.remove("select2-results__option--selected")
        // New line ++
        if (select.offsetLeft === 7 || select.offsetLeft === 10) line++
        // if it's the last line(3th one), set option(select) as the last element to add btn(see more) after it
        if (line === 3) {
          if (select.classList.contains("select2-results__option--selected")) return
          if (select.classList.contains("select2-results__option--disabled")) return
          element = select
          lineElements++
        }
        newli = select
      })
      // Add class to see more btn
      if (!optionmore) {
        optionmore = newli
      }
      if (!(optionmore?.classList.contains("more"))) optionmore?.classList.add("more")
      if (!(optionmore?.classList.contains("text-dark"))) optionmore?.classList.add("text-dark")
      // if more than 3 line and the last element is not see more btn, we setting btn
      if (line > 3) {
        if (optionmore && element && !element.classList.contains("more")) {
          element.parentNode.insertBefore(optionmore, element)
          if (!(element.offsetLeft === 7 || element.offsetLeft === 10) || lineElements === 1) {
            if (optionmore.parentNode) {
              optionmore.parentNode.removeChild(optionmore)
              element.parentNode.insertBefore(optionmore, element.nextSibling)
            }
          }
          optionmore.parentNode.insertBefore(option2, optionmore.nextSibling)
        }
      } else {
        // search if there is a btn see more => hide it
        document.querySelectorAll(".select2-results__option").forEach(select => {
          if (select.classList.contains("more") || select.id.includes("-more")) {
            select.style.display = "none"
          }
        })
      }
    }, 0)
  })

  let isFirstOpen = true
  $("#search").on("select2:open", function (e) { // eslint-disable-line no-undef
    if (isFirstOpen) {
      isFirstOpen = false
    } else {
      // hide dropdown before check if there is keywords or not
      const dropdown = $(e.target).data("select2").$dropdown
      $(dropdown).css({ visibility: "hidden" })
      setTimeout(() => {
        // get options with class "select2-results__option--selectable"
        // and without class "select2-results__option--selected"
        // and without id that contain "more"
        const visibleOptions = document.querySelectorAll(".select2-results__option--selectable:not(.select2-results__option--selected):not([id*='more']")
        if (visibleOptions.length === 0) {
          $(dropdown).css({ visibility: "hidden" })
        } else {
          $(dropdown).css({ visibility: "visible" })
        }
      }, 2)
    }
  })

  // $("#search").on("select2:closing", function (e) { // eslint-disable-line no-undef
  //   let element = null
  //   let i=0
  //   let newli = null
  //   document.querySelectorAll(".select2-results__option").forEach(select => {
  //     if (select.classList.contains("more")) {
  //       select.classList.remove("select2-results__option--selected")
  //     }
  //     if (select.classList.contains("vide")) {
  //       select.classList.add("hidden2")
  //     }
  //   })
  // })
}
function formatState(state) {
  if (!state.id) {
    return state.text.split("<img")[0]
  }
  const $state = $(
    `<span style='${state.id === "more" ? "text-decoration: underline" : ""}' class='keyword-list ${state.id === "more" ? "text-dark" : ""} title= '${state.text.split("<img")[0]}' >` + state.text + "</span>"
  )
  return $state
}

export function cleanSelect(type, value = false) {
  let index = 0
  const selects = document.getElementsByTagName("select")
  let selectedSelect
  selects.forEach(select => {
    if (select.id === type) {
      selectedSelect = select
      return 0
    }
  })
  let selectMate = selectedSelect.closest(".select_mate")
  const selectedIndex = selectMate.getAttribute("data-index-select")
  let elements = document.querySelectorAll("[data-index-select='" + selectedIndex + "'] .select_int > li")
  let selectOptions = document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > select > option")
  if (value) {
    for (let i = 0; i < selectOptions.length; i++) {
      if (selectOptions[i].value === value) {
        index = i
        break
      }
    }
  }

  for (const element of elements) {
    if (element.className === "active") {
      element.className = ""
    }
    if (value) {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].getAttribute("value") === value) {
          index = i
          break
        }
      }
    }
    if (elements[index]) elements[index].className = "active"
  }
  if (document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > .selected_option").length > 0) {
    if (elements[index]) document.querySelectorAll("[data-index-select='" + selectedIndex + "'] > .selected_option")[0].innerHTML = elements[index].innerHTML
  }
  if (selectOptions[index]) selectOptions[index].selected = true
  selectedSelect.selectedIndex = index
}
function flattenarray(array) {
  if (array.length === 0) return []
  return array.reduce(function (prev, next) {
    return prev.concat(next)
  })
}

function shuffle(tab) {
  tab.sort(function (a, b) {
    return Math.random() - Math.random()
  })
}
function arrayRand(tableau, nb = null) {
  if (nb === null) nb = tableau.length
  /* Création du tableau temporaire de travail */
  let tmp = []
  tableau.forEach(function (e) {
    tmp.push(e)
  })
  /* Mélange */
  shuffle(tmp)
  /* Retour du 1er élément ou d'une tranche */
  if (nb === 1) {
    return tmp[0]
  } else {
    return tmp.slice(0, nb)
  }
}

function getMonumentCategory() {
  const monumentsCategory = {
    monuments: {
      PLT: {
        internalId: "PLT",
        category: "PLT",
        name: "Placage",
        config: "veneer",
        description: "Placage",
        granite: 25,
        footstone: "APCC0A",
        headstone: "000",
        databaseId: "b1df8393-e30b-40f0-a130-8e4fa1d49af6",
        graniteMinor: 0,
        useMinorSellingReference: "",
        available: "1",
        disable3d: "",
        isExclusive: "",
        similar: null,
        monumentType: "interment",
        info3dRef: "T101",
        frame: false,
        veneer: true
      },
      ACC: {
        internalId: "ACC",
        category: "ACC",
        name: "Accessoires",
        config: "accessory",
        description: "Accessoires",
        granite: 25,
        footstone: "APCC0A",
        headstone: "000",
        databaseId: "b1df8393-e30b-40f0-a130-8e4fa1d49af6",
        graniteMinor: 0,
        useMinorSellingReference: "",
        available: "1",
        disable3d: "",
        isExclusive: "",
        similar: null,
        monumentType: "interment",
        info3dRef: "T101",
        frame: false,
        veneer: false
      },
      SEM: {
        internalId: "SEM",
        category: "SEM",
        name: "Semelle",
        config: "frame",
        description: "Semelle",
        granite: 25,
        footstone: "APCC0A",
        headstone: "000",
        databaseId: "b1df8393-e30b-40f0-a130-8e4fa1d49af6",
        graniteMinor: 0,
        useMinorSellingReference: "",
        available: "1",
        disable3d: "",
        isExclusive: "",
        similar: null,
        monumentType: "interment",
        info3dRef: "T101",
        frame: true,
        veneer: false
      }
    }
  }
  return monumentsCategory.monuments
}

export function disableImgModal(imgElement) {
  if (imgElement.getAttribute("data-prospace-list") === "true") {
    imgElement.removeAttribute("data-action")
    if (imgElement.getAttribute("data-prospace-parent-action") === "true") {
      imgElement.parentElement.removeAttribute("data-action")
      imgElement.parentElement.removeAttribute("href")
      imgElement.parentElement.removeAttribute("link")
    }
  }
}

async function getNewsTags() {
  const data = await get("/news_tags").then(response => {
    return response.json().then(data => {
      return data
    })
  })
  return data
}
